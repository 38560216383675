import React from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import '../design/Login.css';

class ConfirmReset extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            confirmationCode: ""
        }
    }

    setConfirmation = (newConfirmationCode) => {
        this.setState({
            confirmationCode: newConfirmationCode
        })
    }

    confirmReset = () => {
        
        // Basic Error Handling
        if (this.state.confirmationCode === "") {
            alert("Please enter your confirmation code")
            return
        }

        // Assume that the account was confirmed
        this.props.history.push("/login")
    }

    render() {
        return (
          <div>
            <form className="Login">
              <FormGroup controlId="username" bsSize="large">
                <FormLabel>Confirmation Code</FormLabel>
                <FormControl
                  autoFocus
                  type="confirmationCode"
                  value={this.state.confirmationCode}
                  onChange={e => this.setConfirmation(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                  <Button block bsSize="large" onClick={this.confirmReset}>Confirm</Button> </FormGroup>
            </form>
          </div>
        );
    }
}

export default ConfirmReset;