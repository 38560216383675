import React from "react";
import { Button } from "react-bootstrap";

import ColoredLine from './ColoredLine';

import Lambda from 'aws-sdk/clients/lambda';
import AWS from 'aws-sdk';

class DeckComponent extends React.Component {

    editDeck = () => {
        this.props.history.push(`/set?id=${this.props.deck[0]}`);
    }

    deleteDeck = () => {
        if (window.confirm(`Are you sure you want to delete ${this.props.deck[2]}? This action is irreversible.`)) {
            AWS.config.update({region:"us-east-2"})
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
            })
            let currentComponent = this;
            const lambda = new Lambda({
                credentials: AWS.config.credentials
            });

            var params = {
                FunctionName: 'thivosLambda1_1',
                Payload: JSON.stringify({
                    requestName: "deleteDeck",
                    deckId: this.props.deck[0],
                })
            }

            lambda.invoke(params, function(err,data) {
                if (err) { 
                    alert(err.message)
                } else {
                    let statusCode = JSON.parse(data.Payload).statusCode
                    if (statusCode === 200) {
                        currentComponent.props.delete(currentComponent.props.deck[0])
                    } else {
                        alert("SERVER ERROR: "+statusCode)
                    }
                }
            })
        } else {
            // Do not delete, so do nothing
        }
    }

    render () {
        return(
            <Button onClick={this.editDeck} className="Deck">
                <div>{this.props.deck[2]}</div>
                <ColoredLine color="white" />
                <div>{this.props.deck[1]}</div>
            </Button>
        )
    }
}

export default DeckComponent;