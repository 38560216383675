import React from 'react';
import { Button } from 'react-bootstrap';

import Lambda from 'aws-sdk/clients/lambda';
import AWS from 'aws-sdk';

class ShareButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shared: false
        }
    }

    setShared = (shouldShare) => {
        this.setState({
            shared: shouldShare 
        })
    }

    shareClass = async () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "shareClass",
                deckId: this.props.deckId,
                classId: this.props.classId,
            })
        };
        lambda.invoke(params, function(err,data) {
            if (err) {
                alert(err.message)
            } else {
                let response = data;
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload);
                    if (payload.statusCode === 200) {
                        currentComponent.setShared(true)
                        currentComponent.props.deckShareHandler(currentComponent.props.deckId,currentComponent.props.classId)
                    } else {
                        alert("ERROR: "+JSON.stringify(payload))
                    }
                } else {
                    alert(`AWS ERROR: ${response.StatusCode}`)
                }
            }
        });
    }

    render() {
        return (
            <Button onClick={()=>this.shareClass()}>
                {this.props.text}
            </Button>
        )
    }

}

export default ShareButton;