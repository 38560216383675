import React from "react";
import { Button } from "react-bootstrap";
import { withRouter } from 'react-router';
import Lambda from 'aws-sdk/clients/lambda';
import AWS from 'aws-sdk';
import { CognitoUserPool } from "amazon-cognito-identity-js";
import queryString from 'query-string';
import '../design/StudyMenu.css';

import { config } from './Constants';

import { putAssignmentProgress } from '../serverCalls/awsLambda';

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

class QuestionResponseSectionCell extends React.Component {

    render() {
        return(
            <Button 
                onClick={this.props.selectCallback}
                className="QuestionResponseSectionCell">
                <div className="QuestionResponseSectionCellNumber">
                    <>
                        {this.props.number}
                    </>
                </div>
                <div className="QuestionResponseSectionCellAnswer">
                    <>
                        {this.props.answer}
                    </>
                </div>
            </Button>
        )
    }
}

class StudyMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            prompt: "Question",
            answer1: "Answer 1",
            answer2: "Answer 2",
            answer3: "Answer 3",
            answer4: "Answer 4",
            studyState: "ready",
        };

        // Indicate whether the component is shutting down
        this.active = true;

        window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
        window.SpeechRecognitionList = window.webkitSpeechRecognitionList || window.SpeechRecognitionList;
        if ('SpeechRecognition' in window) {
            console.log("Speech recognition is supported");

            var grammar = '#JSGF V1.0; grammar num; public <num> = 1 | 2 | 3 | 4 ;';
            this.recognition = new window.SpeechRecognition();
            this.recognition.continuous = true;
            var speechGrammarList = new window.webkitSpeechGrammarList();
            speechGrammarList.addFromString(grammar,1);
            this.recognition.grammars = speechGrammarList;

            var currentComponent = this;
            this.recognition.onresult = function(event) {
                var result = event.results[event.results.length-1][0].transcript;
                if (result.includes('1')) {
                    currentComponent.pickAnswer(0);
                    return
                }
                if (result.includes('2')) {
                    currentComponent.pickAnswer(1);
                }
                if (result.includes('3')) {
                    currentComponent.pickAnswer(2);
                }
                if (result.includes('4')) {
                    currentComponent.pickAnswer(3);
                }
            }
 
            this.recognition.start();
        }

        this.running = false;
        this.values = queryString.parse(this.props.location.search);
    }

    componentDidMount() {
        if (this.props.session == null) {
            let fullPath = this.props.history.location.pathname.concat(this.props.history.location.search)
            window.localStorage.setItem('lastLocation',fullPath)
            this.props.history.push("/");
        } else {
            window.localStorage.setItem('lastLocation','/home')
            this.userName = this.props.session.AccessToken.payload.username
            this.getCards();
        }
    }

    componentWillUnmount() {
        this.active = false;
        this.postStudyProgress();
        window.speechSynthesis.cancel();
    }

    // State Management

    setStudyState = (newStudyState) => {
        this.setState({
            prompt: this.state.prompt,
            answer1: this.state.answer1,
            answer2: this.state.answer2,
            answer3: this.state.answer3,
            answer4: this.state.answer4,
            studyState: newStudyState,
        })
    }

    setQuestion = (prompt, answers) => {
        this.setState({
            prompt: prompt,
            answer1: answers[0],
            answer2: answers[1],
            answer3: answers[2],
            answer4: answers[3],
            studyState: this.state.studyState,
        })
    }

    setSession = (newSession) => {
        this.session = newSession
    }

    returnToStudySetViewer = () => {
        this.postStudyProgress()
        this.props.history.push(`/set?id=${parseInt(this.values.id)}`)
    }

    // Study Engine

    pickAnswer = (answerIndex) => {

        // Suspend all speech activity
        window.speechSynthesis.cancel();
        if (this.recognition != null) {
            this.recognition.abort();
        }

        // Detect correct answer
        var correctAnswer = this.cardsSeen[0].answer;
        if (answerIndex === this.correctAnswerIndex) {
            var msg = new SpeechSynthesisUtterance(`Correct, the answer was ${correctAnswer}`);
            var voices = window.speechSynthesis.getVoices();
            msg.voice = voices[0];
            msg.onend = () => {
                this.cardsLearned.push(this.cardsSeen.shift());
                if (this.active) {
                    this.startStudySession();
                }
            };
            window.speechSynthesis.speak(msg);
        } else {
            var msg = new SpeechSynthesisUtterance(`Sorry, the answer was ${correctAnswer}`);
            var voices = window.speechSynthesis.getVoices();
            msg.voice = voices[0];
            msg.onend = () => {
                this.cardsSeen.push(this.cardsSeen.shift());
                if (this.active) {
                    this.startStudySession();
                }
            };
            window.speechSynthesis.speak(msg);
        }
    }

    startStudySession = async () => {
        // Must be called after study sets are loaded

        // Seen Card Queue
        let cardsSeenLimit = 10;
        while ( (this.cardsSeen.length < cardsSeenLimit) && (this.cardsUnseen.length > 0) ) {
            this.cardsSeen.push(this.cardsUnseen.shift());
        }

        // Termination Case
        if ( (this.cardsSeen.length === 0) && (this.cardsUnseen.length === 0) ) {
            // TODO: Implement the termination functionality

            var msg = new SpeechSynthesisUtterance("Congratulations, you answered all the questions!");
            msg.rate = 1.25;
            var voices = window.speechSynthesis.getVoices();
            msg.voice = voices[0];
            msg.onend = () => {
                this.recognition.abort();
            };
            window.speechSynthesis.speak(msg);

            return;
        }

        // Asking the next question
        var answers = [
            this.cardsSeen[0].wrongAnswer1,
            this.cardsSeen[0].wrongAnswer2,
            this.cardsSeen[0].wrongAnswer3,
        ];
        shuffle(answers);
        this.correctAnswerIndex = Math.floor(Math.random() * 4);
        answers.splice(this.correctAnswerIndex,0,this.cardsSeen[0].answer);
        this.setQuestion(this.cardsSeen[0].prompt, answers);

        var msg = new SpeechSynthesisUtterance(
            `${this.cardsSeen[0].prompt}? 
            Is it one: ${answers[0]}.
            Two: ${answers[1]}.
            Three: ${answers[2]}.
            Or four: ${answers[3]}.`
        );
        msg.rate = 1.25;
        var voices = window.speechSynthesis.getVoices();
        msg.voice = voices[0];
        msg.onend = () => {
            window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
            window.SpeechRecognitionList = window.webkitSpeechRecognitionList || window.SpeechRecognitionList;
            if ('SpeechRecognition' in window) {

                var grammar = '#JSGF V1.0; grammar num; public <num> = 1 | 2 | 3 | 4 ;';
                this.recognition = new window.SpeechRecognition();
                this.recognition.continuous = true;
                var speechGrammarList = new window.webkitSpeechGrammarList();
                speechGrammarList.addFromString(grammar,1);
                this.recognition.grammars = speechGrammarList;

                var currentComponent = this;
                this.recognition.onresult = function(event) {
                    var result = event.results[event.results.length-1][0].transcript;
                    if (result.includes('1')) {
                        currentComponent.pickAnswer(0);
                        return
                    }
                    if (result.includes('2')) {
                        currentComponent.pickAnswer(1);
                    }
                    if (result.includes('3')) {
                        currentComponent.pickAnswer(2);
                    }
                    if (result.includes('4')) {
                        currentComponent.pickAnswer(3);
                    }
                }
                this.recognition.start();
            } else {
                console.log("Speech recognition is not supported")
                return;
            }
        };
        window.speechSynthesis.speak(msg);
        
    }

    // Backend Calls

    postStudyProgress = () => {

        // Get the input 
        let userName = this.userName;
        let deckId = parseInt(this.values.id);
        let totalQuestions = this.cardsLearned.length + this.cardsSeen.length + this.cardsUnseen.length;
        let completePct = this.cardsLearned.length / totalQuestions;

        // Post the grades
        putAssignmentProgress(userName,deckId,completePct).then(() => {
            // console.log("Progress saved");
        },(err)=>{
            console.error(err);
        })

    }

    getCards = async () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosCards',
            Payload: JSON.stringify({
                requestName: "getCards",
                userName: currentComponent.userName,
                deckId: parseInt(this.values.id),
            })
        };
        lambda.invoke(params, function(err,data) {
            if (err) {
                alert(err.message)
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {

                        currentComponent.cardsUnseen = payload.cards;
                        currentComponent.cardsSeen = [];
                        currentComponent.cardsLearned = [];

                        currentComponent.setStudyState("running");
                        currentComponent.startStudySession();
                    } else {
                        alert("ERROR: "+payload.statusCode)
                    }
                } else {
                    alert(`ERROR: ${response.StatusCode}`)
                }
            }
        });
    }

    // Graphics

    QuestionPromptSectionContent = (props) => {
        if (props.studyState == "running") {
            return (
                <>
                    {props.prompt}
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    QuestionResponseSectionContent = (props) => {
        if (props.studyState == "running") {
            return (
                <>
                    <div className="QuestionResponseSectionRow">
                        <QuestionResponseSectionCell number="1" answer={props.answer1} selectCallback={()=>props.selectCallback(0)}/>
                        <QuestionResponseSectionCell number="2" answer={props.answer2} selectCallback={()=>props.selectCallback(1)}/>
                    </div>
                    <div className="QuestionResponseSectionRow">
                        <QuestionResponseSectionCell number="3" answer={props.answer3} selectCallback={()=>props.selectCallback(2)}/>
                        <QuestionResponseSectionCell number="4" answer={props.answer4} selectCallback={()=>props.selectCallback(3)}/>
                    </div>
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    render() {
        return (
            <div className="StudyMenuRoot">
                <div className="QuestionPromptSection">
                    <this.QuestionPromptSectionContent studyState={this.state.studyState} prompt={this.state.prompt}/>
                </div>
                <div className="QuestionResponseSection">
                    <div className="QuestionResponseSectionRow">
                        <QuestionResponseSectionCell number="1" answer={this.state.answer1} selectCallback={()=>this.pickAnswer(0)}/>
                        <QuestionResponseSectionCell number="2" answer={this.state.answer2} selectCallback={()=>this.pickAnswer(1)}/>
                    </div>
                    <div className="QuestionResponseSectionRow">
                        <QuestionResponseSectionCell number="3" answer={this.state.answer3} selectCallback={()=>this.pickAnswer(2)}/>
                        <QuestionResponseSectionCell number="4" answer={this.state.answer4} selectCallback={()=>this.pickAnswer(3)}/>
                    </div>
                </div>
                <div className="StudyControlSection">
                    <Button onClick={this.returnToStudySetViewer} className="ExitButton">Exit</Button>
                </div>
            </div>
        )
    }

}

export default StudyMenu;