const prod = {
    uri : {
        REDIRECT_URI_ENCODED: "https%3A%2F%2Fstudy.thivos.com%2F",
        REDIRECT_URI_DECODED: "https://study.thivos.com/",
    },
    url : {
        SIGNOUT_URL: "https://auth.thivos.com/login?client_id=40h7bi1nhm79mdh6r5na8igv84&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://study.thivos.com/",
    },
}

const dev = {
    uri : {
        REDIRECT_URI_ENCODED: "http%3A%2F%2Flocalhost%3A3000%2F",
        REDIRECT_URI_DECODED: "http://localhost:3000/",
    },
    url : {
        SIGNOUT_URL: "https://auth.thivos.com/login?client_id=40h7bi1nhm79mdh6r5na8igv84&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http://localhost:3000/",
    },
}

export const config = process.env.NODE_ENV === "development" ? dev : prod;