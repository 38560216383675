import Lambda from 'aws-sdk/clients/lambda';
import AWS from 'aws-sdk';

import { addAssignmentToGoogleClassrooms } from './googleClassroom';

let callLambda = (requirements, payload) => {
    return new Promise((resolve,reject) => {

        // Type Checking
        // NOTE: I should just start using typescript
        Object.keys(requirements).forEach(reqKey => {
            if (typeof payload[reqKey] != requirements[reqKey]) {
                console.error(`${reqKey} must be of type ${requirements[reqKey]}`);
            }
        })

        // Calling the function
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify(payload)
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                reject(err);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        resolve(payload);
                    } else {
                        reject(payload);
                    }
                } else {
                    reject(`ERROR: ${response}`);
                }
            }
        })
    })
}

//////////////////////////////////////////////////

export let putGoogleClassLink = (classId,googleClassId) => {
    return new Promise((resolve,reject) => {
        let requirements = {
            "requestName"   :   "string",
            "classId"       :   "number",
            "googleClassId" :   "number"
        }
        let payload = {
            "requestName"   :   "putGoogleClassLink",
            "classId"       :   classId,
            "googleClassId" :   googleClassId
        }
        callLambda(requirements,payload).then(()=>{
            resolve();
        }).catch((err)=>{
            reject(err);
        })
    })
}

export let inviteToClassUsernames = (classId, userNames) => {
    return new Promise((resolve,reject) => {

        if (userNames === undefined || userNames.length === 0) {
            // There are no usernames to add
            resolve();
        }

        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "inviteToClassUsernames",
                classId: classId,
                userNames: userNames
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                reject(err);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        // Don't do anything at all
                        // Well, maybe add the students to the thing
                        resolve();
                    } else {
                        reject(payload)
                    }
                } else {
                    reject(`ERROR: ${response}`)
                }
            }
        })
    });
}

export let putGoogleAssignmentLinks = (classId, deckId, googleAssignmentId) => {
    return new Promise((resolve,reject) => {

        // Error handling
        if (typeof classId != 'number' ||
            typeof deckId != 'number' ||
            typeof googleAssignmentId != 'number')
        {
            reject({
                'error' : 'classId, deckId, and googleAssignmentId must be of type number'
            })
        }

        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "putGoogleAssignmentLinks",
                classId: classId,
                deckId: deckId,
                googleAssignmentIds: googleAssignmentId,
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                reject(err);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        resolve();
                    } else {
                        reject(payload)
                    }
                } else {
                    reject(`ERROR: ${response}`)
                }
            }
        })
    });
}

export let getLinkedGoogleClasses = (classId) => {
    return new Promise((resolve, reject) => {
        let requirements = {
            "requestName"   :   "string",
            "classId"       :   "number",
        }
        let payload = {
            "requestName"   :   "getLinkedGoogleClasses",
            "classId"       :   classId,
        }
        callLambda(requirements,payload).then((response)=>{
            resolve({
                linkedClasses: JSON.parse(response.linkedClasses)
            });
        }).catch((err)=>{
            reject(err);
        })
    })
}

export let putAssignmentProgress = (userName,deckId,progressPct) => {
    return new Promise((resolve, reject) => {
        let requirements = {
            "requestName"   :   "string",
            "deckId"        :   "number",
            "userName"      :   "string",
            "progressPct"   :   "number"
        }
        let payload = {
            "requestName"   :   "putAssignmentProgress",
            "deckId"        :   deckId,
            "userName"      :   userName,
            "progressPct"   :   progressPct 
        }
        callLambda(requirements,payload).then(()=>{
            resolve() // Nothing is returned from this lambda
        },(err)=>{
            reject(err);
        })
    })
}

export let getDeckHeader = (deckId) => {
    return new Promise((resolve,reject)=>{
        let requirements = {
            "requestName"   :   "string",
            "deckId"        :   "number"
        }
        let payload = {
            "requestName"   :   "getDeckHeader",
            "deckId"        :   deckId
        }
        callLambda(requirements,payload).then((response)=>{
            resolve(response)
        },(err)=>{
            reject(err);
        })
    })
}

export let addAssignmentToClass = (deckId, classId, title, instructions, dueDate) => {
    return new Promise((resolve, reject) => {
        let requirements = {
            "requestName"   :   "string",
            "deckId"        :   "number",
            "classId"       :   "number",
            "title"         :   "string",
            "instructions"  :   "string",
            "dueDate"       :   "string",
        }
        let payload = {
            "requestName"   :   "addAssignmentToClass",
            "deckId"        :   deckId,
            "classId"       :   classId,
            "title"         :   title,
            "instructions"  :   instructions,
            "dueDate"       :   dueDate,
        }
        callLambda(requirements,payload).then(()=>{
            resolve()
        },(err)=>{
            reject(err)
        })
    })
}

export let addAssignmentToGoogleClassroom = (deckId, classId, title, instructions, dueDate) => {
    return new Promise((resolve, reject) => {

            // Extracting the dueDate and dueTime
            let re = new RegExp('([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})');
            let match = dueDate.match(re);

            // Configure the assignment data structure
            const newCourseworkPayload = {
                title: title,
                description: instructions,
                dueDate: {
                    year: match[1],
                    month: match[2],
                    day: match[3],
                },
                dueTime: {
                    hours: match[4],
                    minutes: match[5],
                    seconds: match[6],
                    nanos: 0,
                },
                state: 'PUBLISHED',
                maxPoints: 100,
                workType: 'ASSIGNMENT',
            }

            // Add the assignment to each linked class
            this.state.linkedGoogleClasses.forEach(googleClassId => {
                // Add the assignment to each class
                addAssignmentToGoogleClassrooms(googleClassId,newCourseworkPayload).then((response)=>{
                    putGoogleAssignmentLinks(deckId,classId,parseInt(response.googleAssignmentId)).then(()=>{
                        resolve({
                            googleAssignmentId: parseInt(response.googleAssignmentId)
                        })
                    },(err)=>{
                        reject(err);
                    })
                },(err)=>{
                    reject(err);
                })
            })

    })
}