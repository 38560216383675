import React from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import '../design/Login.css';
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: ""
        }
    }

    resetPassword = async () => {
        // Basic Error Checking
        if (this.state.username === "") {
            alert("Please enter a username.");
            return;
        }

        // AWS Authentication : Pool Information
        var poolData = {
            UserPoolId : 'us-east-2_6ae0DTaEB', // your user pool id here
            ClientId : '40h7bi1nhm79mdh6r5na8igv84' // your app client id here
        };
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username : this.state.username, // your username here
            Pool : userPool
        };

        var rstComp = this;
        var cognitoUser = new CognitoUser(userData);
        await cognitoUser.forgotPassword({
          onSuccess: function(result) {
            rstComp.props.history.push("/");
          },
          onFailure: function(err) {
            alert(err.message)
          },
          inputVerificationCode() {
            var verificationCode = prompt("Please input verification code ","");
            var newPassword = prompt("Enter new password ","");
            cognitoUser.confirmPassword(verificationCode,newPassword,this);
          }
        });
    }

    setUsername = (newUsername) => {
        this.setState({
            username: newUsername
        })
    }

    render() {
        return (
          <div>
            <form className="Login">
              <FormGroup controlId="username" bsSize="large">
                <FormLabel>Username</FormLabel>
                <FormControl
                  autoFocus
                  type="username"
                  value={this.state.username}
                  onChange={e => this.setUsername(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                  <Button block bsSize="large" onClick={this.resetPassword}>Reset Password</Button>
              </FormGroup>
            </form>
          </div>
        );
    }
}

export default ResetPassword;

