let listStudentIds = (courseId) => {
    return new Promise((resolve,reject) => {
        window.gapi.client.classroom.courses.students.list({'courseId':`${courseId}`}).execute(function(response) {
            // Add student accounts to the Thivos Database
            if (response.students != null) {
                let userNames = response.students.map(student => 
                    `Google_${student.userId}`
                );
                if (userNames.length > 0) {
                    resolve({
                        "userNames": userNames
                    });
                }
            }
            // No Students Found
            resolve({
                "userNames" : []
            })
        });
    });
}

let addAssignmentToGoogleClassrooms = (googleClassId,courseworkPayload) => {
    return new Promise((resolve,reject)=>{
        window.gapi.client.classroom.courses.courseWork.create({
            courseId: googleClassId,
            resource: courseworkPayload
        }).then((response)=>{
            resolve({
                googleAssignmentId: response.result.id
            });
        },(err)=>{
            reject(err);
        })
    })
}

export { listStudentIds, addAssignmentToGoogleClassrooms };