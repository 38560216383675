import React from "react";
import { Button, Modal, Form, Nav, Navbar, ButtonToolbar } from "react-bootstrap";
// import '../design/Login.css';
import { CognitoUserPool } from "amazon-cognito-identity-js";
import Lambda from 'aws-sdk/clients/lambda';
import AWS from 'aws-sdk';

import { withRouter } from 'react-router';

import Logo from '../design/logo192.png';
import ColoredLine from './subComponents/ColoredLine';

import DeckComponent from './subComponents/DeckComponent';
import EnrolledClassComponent from './subComponents/EnrolledClassComponent';
import TaughtClassComponent from './subComponents/TaughtClassComponent';

import '../design/Home.css';
import { config } from './Constants';

class MainMenu extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            decks: [],
            enrolledClasses: [],
            taughtClasses: [],
            isTeacher: false,
            mode: "studySet",
            newStudySetModal: false,
            userSettingsModal: false,
            joinClassModal: false,
            createClassModal: false,
            studySetTitle: "",
            classTitle: "",
            classDescription: "",
            classInvitations: []
        }

    }

    componentDidMount() {
        if (this.props.session == null) {
            let fullPath = this.props.history.location.pathname.concat(this.props.history.location.search)
            window.localStorage.setItem('lastLocation',fullPath)
            this.props.history.push("/");
        } else {
            window.localStorage.setItem('lastLocation','/home')
            this.userName = this.props.session.AccessToken.payload.username
            this.getDecks()
            this.getClassesTaught()
            this.getClassesEnrolled()
            this.getClassesInvited()
            this.getSettings();
        }
    }

    StudentSection = (props) => {
        var renderEnrolledClasses = () => {
            if (this.props.enrolledClasses.length === 0) {
                return (
                    <div className="DecksHeader">
                        You are not enrolled in any classes.
                    </div>
                )
            } else {
                return (
                    this.props.enrolledClasses.map(thisClass =>
                        <div>{<EnrolledClassComponent class={thisClass}/>}</div>
                    )
                )
            }
        }
        return (
            <div>
                <div className="SetSectionHeader">
                    Your Enrolled Classes
                </div>
                <div>
                    {renderEnrolledClasses()}
                </div>
            </div>
        )
    }

    TeacherSection = (props) => {
        let renderTaughtClasses = () => {
            if (this.props.taughtClasses.length === 0) {
                return (
                    <div className="DecksHeader">
                        You do not teach any classes.
                    </div>
                )
            } else {
                return (
                    this.props.taughtClasses.map(thisClass =>
                    <div>{<TaughtClassComponent class={thisClass}/>}</div>
                    )
                )
            }
        }
        if (this.props.isTeacher === true) {
            return (
                <div>
                    <div className="SetSectionHeader">
                        Your Taught Classes
                    </div>
                    <div>
                        {renderTaughtClasses()}
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    CreateClassButton = (props) => {
        if (this.props.isTeacher === true) {
            return (
                <Button onClick={this.createClass}>Create a Class</Button>
            )
        } else {
            return null
        }
    }

    createClass = (classTitle, classDescription) => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "createClass",
                userName: currentComponent.userName,
                classTitle: classTitle,
                classDescription: classDescription,
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                alert(err.message)
            } else {
                let statusCode = JSON.parse(data.Payload).statusCode
                let payload = JSON.parse(data.Payload)
                if (statusCode === 200) {
                    currentComponent.goToClass({
                        classId: payload.classId,
                        title: payload.title,
                        description: payload.description,
                        teacherUsername: payload.teacherUsername,
                    })
                } else {
                    alert("ERROR: "+statusCode+", "+JSON.parse(data.Payload).body)
                }
            }
        })
    }


    setSession = (newSession) => {
        this.session = newSession
    }

    setDecks = (newDecks) => {
        this.setState({
            decks: newDecks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: this.state.mode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: this.state.joinClassModal,
            createClassModal: this.state.createClassModal,
            studySetTitle: this.state.studySetTitle,
            classTitle: this.state.classTitle,
            classDescription: this.state.classDescription,
            classInvitations: this.state.classInvitations
        })
    }

    setTaughtClasses = (taughtClasses) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: this.state.mode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: this.state.joinClassModal,
            createClassModal: this.state.createClassModal,
            studySetTitle: this.state.studySetTitle,
            classTitle: this.state.classTitle,
            classDescription: this.state.classDescription,
            classInvitations: this.state.classInvitations
        })
    }

    setEnrolledClasses = (enrolledClasses) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: this.state.mode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: this.state.joinClassModal,
            createClassModal: this.state.createClassModal,
            studySetTitle: this.state.studySetTitle,
            classTitle: this.state.classTitle,
            classDescription: this.state.classDescription,
            classInvitations: this.state.classInvitations
        })
    }

    setIsTeacher = (isTeacher) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: isTeacher,
            dataLoaded: true,
            mode: this.state.mode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: this.state.joinClassModal,
            createClassModal: this.state.createClassModal,
            studySetTitle: this.state.studySetTitle,
            classTitle: this.state.classTitle,
            classDescription: this.state.classDescription,
            classInvitations: this.state.classInvitations
        })
    }

    setMode = (newMode) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: newMode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: this.state.joinClassModal,
            createClassModal: this.state.createClassModal,
            studySetTitle: this.state.studySetTitle,
            classTitle: this.state.classTitle,
            classDescription: this.state.classDescription,
            classInvitations: this.state.classInvitations
        })
    }

    setNewStudySetModal = (isVisible) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: this.state.mode,
            newStudySetModal: isVisible,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: this.state.joinClassModal,
            createClassModal: this.state.createClassModal,
            studySetTitle: this.state.studySetTitle,
            classTitle: this.state.classTitle,
            classDescription: this.state.classDescription,
            classInvitations: this.state.classInvitations
        })
    }

    setUserSettingsModal = (isVisible) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: this.state.mode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: isVisible,
            joinClassModal: this.state.joinClassModal,
            createClassModal: this.state.createClassModal,
            studySetTitle: this.state.studySetTitle,
            classTitle: this.state.classTitle,
            classDescription: this.state.classDescription,
            classInvitations: this.state.classInvitations
        })
    }
    
    setNewStudySetTitle = (title) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: this.state.mode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: this.state.joinClassModal,
            createClassModal: this.state.createClassModal,
            studySetTitle: title,
            classTitle: this.state.classTitle,
            classDescription: this.state.classDescription,
            classInvitations: this.state.classInvitations
        })
    }

    joinClassModal = (isVisible) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: this.state.mode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: isVisible,
            createClassModal: this.state.createClassModal,
            studySetTitle: this.state.studySetTitle,
            classTitle: this.state.classTitle,
            classDescription: this.state.classDescription,
            classInvitations: this.state.classInvitations
        })
    }

    createClassModal = (isVisible) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: this.state.mode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: this.state.joinClassModal,
            createClassModal: isVisible,
            studySetTitle: this.state.studySetTitle,
            classTitle: this.state.classTitle,
            classDescription: this.state.classDescription,
            classInvitations: this.state.classInvitations
        })
    }

    setNewClassTitle = (isVisible) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: this.state.mode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: this.state.joinClassModal,
            createClassModal: this.state.createClassModal,
            studySetTitle: this.state.studySetTitle,
            classTitle: isVisible,
            classDescription: this.state.classDescription,
            classInvitations: this.state.classInvitations
        })
    }

    setNewClassDescription = (classDescription) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: this.state.mode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: this.state.joinClassModal,
            createClassModal: this.state.createClassModal,
            studySetTitle: this.state.studySetTitle,
            classTitle: this.state.classTitle,
            classDescription: classDescription,
            classInvitations: this.state.classInvitations
        })
    }

    setClassInvitations = (classInvitations) => {
        this.setState({
            decks: this.state.decks,
            enrolledClasses: this.state.enrolledClasses,
            taughtClasses: this.state.taughtClasses,
            isTeacher: this.state.isTeacher,
            dataLoaded: this.state.dataLoaded,
            mode: this.state.mode,
            newStudySetModal: this.state.newStudySetModal,
            userSettingsModal: this.state.userSettingsModal,
            joinClassModal: this.state.joinClassModal,
            createClassModal: this.state.createClassModal,
            studySetTitle: this.state.studySetTitle,
            classTitle: this.state.classTitle,
            classDescription: this.state.classDescription,
            classInvitations: classInvitations
        })
    }

    getSettings = () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosCards',
            Payload: JSON.stringify({
                requestName: "getSettings",
                userName: currentComponent.userName
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                alert(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        currentComponent.setIsTeacher(payload.isTeacher)
                    } else {
                        alert(payload.body)
                    }
                } else {
                    alert(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }
    
    putSettings = async(newIsTeacher) => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosCards',
            Payload: JSON.stringify({
                requestName: "putSettings",
                userName: currentComponent.userName,
                isTeacher: newIsTeacher
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                alert(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        // Celebration
                    } else {
                        alert(payload.body)
                    }
                } else {
                    alert(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }

    signOut = async () => {
        window.location.replace(config.url.SIGNOUT_URL);
    }

    goToClass = (targetClass) => {
        this.props.history.push(`/class?id=${targetClass.classId}`)
    }

    goToSettings = () => {
        this.props.history.push("/settings");
    }

    createDeck = (studySetTitle) => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "createDeck",
                userName: currentComponent.userName,
                title: studySetTitle,
            })
        }

        lambda.invoke(params, function(err,data) {
            if (err) { 
                alert(err.message)
            } else {
                let statusCode = JSON.parse(data.Payload).statusCode
                let payload = JSON.parse(data.Payload)
                if (statusCode === 200) {
                    currentComponent.props.history.push(`/set?id=${payload.deckId}`);
                } else {
                    alert("ERROR: "+statusCode)
                }
            }
        })
    }

    getDecks = async() => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getDecks",
                userName: currentComponent.userName
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) alert(err.message)
            else currentComponent.setDecks(JSON.parse(JSON.parse(data.Payload).decks))
        })
    }

    getClassesTaught = async() => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getClassesTaught",
                userName: currentComponent.userName
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) alert(err.message)
            else currentComponent.setTaughtClasses(JSON.parse(JSON.parse(data.Payload).classes))
        })
    }

    getClassesInvited = async() => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getClassesInvited",
                userName: currentComponent.userName
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) alert(err.message)
            else currentComponent.setClassInvitations(JSON.parse(JSON.parse(data.Payload).classes))
        })
    }

    acceptClassInvitation = (data) => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        let theClass = data;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "acceptClassInvitation",
                classId: data[0], // This is the class Id
                userName: currentComponent.userName
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                alert(err.message)
            } else {
                var newClassesEnrolled = currentComponent.state.enrolledClasses;
                var newClassesInvited = currentComponent.state.classInvitations;

                var i;
                for (i = 0; i < newClassesInvited.length; i++) {
                    // Move the target element from one array to the other
                    if (newClassesInvited[i][0] === theClass[0]) {

                        // Update the local state
                        newClassesInvited.splice(i,1);
                        newClassesEnrolled.unshift(theClass);
                        currentComponent.setClassInvitations(newClassesInvited);
                        currentComponent.setEnrolledClasses(newClassesEnrolled);
                        return;
                    }
                }
            }
        })
    }

    getClassesEnrolled = async() => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getClassesEnrolled",
                userName: currentComponent.userName
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) alert(err.message)
            else currentComponent.setEnrolledClasses(JSON.parse(JSON.parse(data.Payload).classes))
        })
    }

    handleDelete = (deckId) => {
        var newDecks = []
        this.state.decks.forEach(element => {
            if (element[0] === deckId) {
                // Do nothing
            } else {
                newDecks.push(element)
            }
        });
        this.setDecks(newDecks)
    }

    renderDecks() {
        if (this.state.decks.length === 0) {
            return (
                <div className="DecksHeader">
                    You do not have any study sets.
                </div>
            )
        } else {
            return (
                this.state.decks.map(thisDeck =>
                    <>{<DeckComponent key={thisDeck[0]} delete={this.handleDelete} history={this.props.history} deck={thisDeck}/>}</>
                )
            )
        }
    }

    Class = (props) => {
        var title = props.theClass[1];
        var description = props.theClass[2];
        var owner = props.theClass[3];
        return (
            <Button className="Deck" onClick={
                () => {
                    this.goToClass({
                        classId: props.theClass[0],
                        title: props.theClass[1],
                        description: props.theClass[2],
                        teacherUsername: props.theClass[3],
                    })
                }
            }>
                <div>{title} | {owner} </div>
                <ColoredLine color="white" />
                <div>{description}</div>
            </Button>
        )
    }

    renderClasses = () => {
        var allClasses = this.state.enrolledClasses.concat(this.state.taughtClasses);
        if (allClasses.length === 0) {
            return (
                <div className="DecksHeader">
                    You do not have any study sets.
                </div>
            ) 
        } else {
            return (
                allClasses.map(thisClass =>
                    <div>{<this.Class theClass={thisClass}/>}</div>
                )
            )
        }
    }

    NewStudySetModal = () => {
        if (this.state.visibleModal === "NewStudySet") {
            const backdropStyle = {
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'rgba(0,0,0.3)',
                padding: 50
            };

            const modalStyle = {
                backgroundColor: '#ffffff',
                borderRadius: 5,
                maxWidth: 500,
                minHeight: 300,
                margin: '0 auto',
                padding: 30
            };

            return (
                <div style={{backdropStyle}}>
                    <div style={{modalStyle}}>
                       New Study Set Modal 
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    MenuSubContent = (props) => {
        if (this.state.mode === "studySet") {
            return (
                <div className="StudySets">
                    <ButtonToolbar className="StudySetHeaderTable">
                        <Button className="StudySetHeaderButton" onClick={() => this.setNewStudySetModal(true)}>
                            + Create Study Set
                        </Button>
                    </ButtonToolbar>
                    {this.renderDecks()}
                </div>
            );
        }

        if (this.state.mode === "class") {
            return (
                <div className="StudySets">
                    <ButtonToolbar className="StudySetHeaderTable">
                        <Button onClick={() => this.joinClassModal(true)} className="StudySetHeaderButton">
                            + Join Class
                        </Button>
                        <Button onClick={() => this.createClassModal(true)} className="StudySetHeaderButton">
                            + Create Class
                        </Button>
                    </ButtonToolbar>
                    {this.renderClasses()}
                </div>
            )
        }
    }

    setTitleChanged = (event) => {
        this.setNewStudySetTitle(event.target.value);
    }

    classTitleChanged = (event) => {
        this.setNewClassTitle(event.target.value);
    }

    classDescriptionChanged = (event) => {
        this.setNewClassDescription(event.target.value);
    }

    teacherText = () => {
        if (this.state.isTeacher === 1) {
            return "Disable Teacher Mode"
        } else if (this.state.isTeacher === 0) {
            return "Enable Teacher Mode"
        }
    }

    toggleTeacher = () => {
        if (this.state.isTeacher === 0) {
           this.setIsTeacher(1);
           this.putSettings(1); 
        } else {
            this.setIsTeacher(0);
            this.putSettings(0);
        }
    }

    isEmpty = (rawString) => {
        if (rawString === null) {
            return false;
        }
        if (/\S/.test(rawString)) {
            // Contains a non whitespace character
            return false;
        }
        return true;
    }

    validClass = () => {
        if (this.isEmpty(this.state.classTitle)) {
            alert("Please enter a class title.")
            return false;
        } else if (this.isEmpty(this.state.classDescription)) {
            alert("Please enter a class description.")
            return false;
        } 
        return true;
    };

    validStudySet = () => {
        if (this.isEmpty(this.state.studySetTitle)) {
            alert("Please enter a title.")
            return false;
        }
        return true;
    };

    ClassInvitation = (props) => {
        var title = props.data[1];
        if (!/\S/.test(title)) {
            title = "Missing Title"
        }
        return (
            <>
                {title}
                <Button onClick={() => {
                    this.acceptClassInvitation(props.data);
                    this.joinClassModal(false);
                }}>
                    Enroll
                </Button>
                <ColoredLine color="gray"/>
            </>
        )
    }

    ClassInvitations = () => {
        if (this.state.classInvitations.length === 0) {
            return (
                <div>
                    You do not have any class invitations.
                </div>
            )
        } else {
            return (
                this.state.classInvitations.map(classInvitation => 
                    <this.ClassInvitation data={classInvitation}/>
                )
            )
        }
    }

    render() {
        return (
            <>
                <Modal
                    show={this.state.userSettingsModal}
                    centered="true"
                    scrollable="true"
                    onHide={ ()=>this.setUserSettingsModal(false) }>
                    <Modal.Header closeButton>
                        <Modal.Title>User Settings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Button 
                                onClick={this.toggleTeacher}
                                disabled={!this.state.dataLoaded}>
                               
                                {this.teacherText()}
                            </Button>
                        </div>
                        <div></div>
                        <div>
                            <Button variant="danger" onClick={this.signOut}>Sign Out</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.joinClassModal}
                    centered="true"
                    scrollable="true"
                    onHide={()=>this.joinClassModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Join a Class</Modal.Title>
                    </Modal.Header>
                    <Modal.Body closeButton>
                        <Modal.Title>Class Invitations</Modal.Title>
                        <this.ClassInvitations/>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.createClassModal}
                    centered = "true"
                    scrollale="true"
                    onHide={()=>this.createClassModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create a New Class</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            placeholder="Title"
                            value={this.state.classTitle}
                            onChange={this.classTitleChanged}
                        />
                        <br/>
                        <Form.Control
                            placeholder="Description"
                            value={this.state.classDescription}
                            onChange={this.classDescriptionChanged}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=>{
                            if (this.validClass() === true) {
                                this.createClass(this.state.classTitle,this.state.classDescription);
                            }
                        }}>
                            Create
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal 
                    show={this.state.newStudySetModal}
                    centered="true"
                    scrollable="true"
                    onHide={ ()=>this.setNewStudySetModal(false) }>
                    <Modal.Header closeButton>
                        <Modal.Title>Create a New Study Set</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control 
                            placeholder="Title" 
                            value={this.state.studySetTitle}
                            onChange={this.setTitleChanged}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=>{
                            if (this.validStudySet()) {
                                this.createDeck(this.state.studySetTitle)
                            }
                        }} >
                            Create
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div>
                    { /* Section for Modals */ }

                    <Navbar className="MainMenuHeader justify-content-between">
                        <Navbar.Brand onClick={()=>this.props.history.push('/home')}>
                            <img alt="Thivos Logo" src={Logo} height='70'/>
                        </Navbar.Brand>
                        <Nav activeKey={this.state.mode} variant="tabs" onSelect={(e)=>this.setMode(e)}>
                            <Nav.Item className="MainMenuItem">
                                <Nav.Link eventKey="studySet">Your Study Sets</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="class">Your Classes</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Button className="SettingsButton" onClick={()=>this.setUserSettingsModal(true)}>
                            Settings
                        </Button>
                    </Navbar>
                    
                    <this.MenuSubContent/>

                    { /* <this.TeacherSection taughtClasses={this.state.taughtClasses} isTeacher={this.state.isTeacher}/> */ }
                    { /* <this.StudentSection enrolledClasses={this.state.enrolledClasses} /> */ }

                </div>
            </>
        );
    }
}

export default withRouter(MainMenu);