import React from 'react';
import { Nav, Navbar, Modal, Button, Form } from 'react-bootstrap';
import ShareButton from './subComponents/ShareButton';
import '../design/ClassHome.css';
import Logo from '../design/logo192.png';
import ColoredLine from './subComponents/ColoredLine';
import ClassInvitationList from './subComponents/ClassInvitationList';

import { withRouter } from 'react-router';

import queryString from 'query-string';

import { CognitoUserPool } from "amazon-cognito-identity-js";
import Lambda from 'aws-sdk/clients/lambda';
import AWS from 'aws-sdk';

import { config } from './Constants';

import { getDeckHeader, putGoogleClassLink, inviteToClassUsernames, putGoogleAssignmentLinks, getLinkedGoogleClasses, addAssignmentToClass } from '../serverCalls/awsLambda';
import { listStudentIds, addAssignmentToGoogleClassrooms } from '../serverCalls/googleClassroom';

var isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
}

var signalMissingTitle = (rawText) => {
    if (isEmptyOrSpaces(rawText)) {
        return "No Title";
    } else {
        return rawText;
    }
}

var signalMissingDescription = (rawText) => {
    if (isEmptyOrSpaces(rawText)) {
        return "No Description";
    } else {
        return rawText;
    }
}

var accountLinkedToGoogle = (identities) => {
    var output = false;
    identities.forEach((identity) => {
        if (identity.providerName === "Google") {
            output = true;
        }
    })
    return output;
}

class AssignStudySetButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clickable: true,
            buttonText: "Assign",
        }
    }

    render() {
        return (
            <Button className="AssignmentRowButton" onClick={()=>this.props.configureAssignment(this.props.classId, this.props.deckId)} disabled={!this.state.clickable}>
                {this.state.buttonText}
            </Button>
        )
    }
}

class ShareGoogleClassButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clickable: true,
            buttonText: "Share",
        }
    }

    linkToClass = () => {
        var currentComponent = this;

        // Get student Ids for google class
        // Invite students to class
        // Create relation between google class Id and thivos class Id
        listStudentIds(this.props.googleClassId).then((response) => {

            // Return new list of students to parent component
            if (this.props.newStudentCallback !== undefined) {
                this.props.newStudentCallback(response.userNames)
            }

            inviteToClassUsernames(this.props.classId,response.userNames).then((response) => {
                putGoogleClassLink(this.props.classId,parseInt(this.props.googleClassId))
                currentComponent.props.linkClassHander(this.props.classId,parseInt(this.props.googleClassId))
            },(err) => {
                console.error(err);
            });
        },(err) => {
            console.error(err);
        });

        // Link all assignments to the google class
        this.props.assignments.forEach(assignment=>{

            // Extracting the dueDate and dueTime
            let re = new RegExp('([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})');
            let match = assignment[2].match(re);

            // Configure the assignment data structure
            const newCourseworkPayload = {
                title: assignment[0],
                description: assignment[1],
                dueDate: {
                    year: match[1],
                    month: match[2],
                    day: match[3],
                },
                dueTime: {
                    hours: match[4],
                    minutes: match[5],
                    seconds: match[6],
                    nanos: 0,
                },
                state: 'PUBLISHED',
                maxPoints: 100,
                workType: 'ASSIGNMENT',
            }

            addAssignmentToGoogleClassrooms(this.props.googleClassId,newCourseworkPayload).then((response)=>{
                putGoogleAssignmentLinks(assignment[4],this.props.classId,parseInt(response.googleAssignmentId)).then(()=>{
                    // Assignment Added
                },(err)=>{
                    console.error(err);
                })
            },(err)=>{
                console.error(err);
            })

        })

    }

    render() {
        return (
            <Button onClick={this.linkToClass} className="GoogleClassRowButton">
                {this.state.buttonText}
            </Button>
        )
    }
}

class ClassHome extends React.Component {

    constructor(props) {
        super(props);

        this.values = queryString.parse(this.props.location.search);

        this.state = {
            title: "",
            description: "",
            teacherUsername: "",
            mode: "studySet",
            shareStudySetModal: false,
            linkToGoogleClassroomModal: false,
            unsharedDecks: [],
            sharedDecks: [],
            invitedStudents: [],
            enrolledStudents: [],
            classId: parseInt(this.values.id),
            userName: " ",
            classesGoogle: [],
        };

        console.log(window.gapi)

    }

    componentDidMount() {
        if (this.props.session == null) {
            let fullPath = this.props.history.location.pathname.concat(this.props.history.location.search)
            window.localStorage.setItem('lastLocation',fullPath)
            this.props.history.push("/");
        } else {
            window.localStorage.setItem('lastLocation','/home')
            if (this.props.session.IdToken.payload.identities != undefined && accountLinkedToGoogle(this.props.session.IdToken.payload.identities)) {
                this.setState({'accountHasGoogleIdentity':true})
                this.getGoogleStudents()
            }
            this.userName = this.props.session.AccessToken.payload.username
            this.setState({userName:this.userName})
            this.getClassHeader();
            this.getSharedDecks();
            this.getStudentsInClass();
            this.getStudentsInvited();

            // NOTE: This needs to have different behavior for student accounts
            this.getTeacherAssignments(this.state.classId);
        }
    }

    // State Management

    setTitle = (newTitle) => {
        this.setState({
            title: newTitle,
        })
    }

    setDescription = (newDescription) => {
        this.setState({
            description: newDescription,
        })
    }

    setTeacherUsername = (newTeacherUsername) => {
        this.setState({
            teacherUsername: newTeacherUsername,
        })
    }

    setMode = (newMode) => {
        this.setState({
            mode: newMode,
        })
    }

    setShareStudySetModal = (isVisible) => {
        this.setState({
            shareStudySetModal: isVisible,
        })
    }
    
    setUnsharedDecks = (newDecks) => {
        this.setState({
            unsharedDecks: newDecks,
        })
    }

    setSharedDecks = (newDecks) => {
        this.setState({
            sharedDecks: newDecks,
        })
    }

    setInvitedStudents = (students) => {
        this.setState({
            invitedStudents: students,
        })
    }

    setEnrolledStudents = (students) => {
        this.setState({
            enrolledStudents: students,
        })
    }

    setAddStudentsModal = (isVisible) => {
        this.setState({
            addStudentsModal: isVisible,
        })
    }

    addNewInvitedStudents = (invitedStudentList) => {
        var newInvitedStudents = [];
        invitedStudentList.forEach(userName => {
            if (this.state.invitedStudents.indexOf(userName)===-1 && this.state.enrolledStudents.indexOf(userName)===-1) {
                newInvitedStudents = newInvitedStudents.concat([userName]);
            }
        })
        this.setState({
            invitedStudents: this.state.invitedStudents.concat(newInvitedStudents),
        })
    }

    setLinkToGoogleClassroomModal = (showLinkToGCModal) => {
        this.setState({
            linkToGoogleClassroomModal: showLinkToGCModal,
        })
    }

    setAddAssignmentModal = (showAddAssignmentModal) => {
        this.setState({
            addAssignmentModal: showAddAssignmentModal
        })
    }

    configureAssignment = (classId, deckId) => {
        this.setState({
            addAssignmentModal: false,
            configureAssignmentModal: true,
            newAssignmentConfiguration: {
                classId: classId,
                deckId: deckId,
            }
        })
    }

    deckShareHandler = (deckId, classId) => {
        var newUnsharedDecks = this.state.unsharedDecks;
        var newSharedDecks = this.state.sharedDecks;
        newUnsharedDecks.some((deck,index)=>{
            if (deck[0] === deckId) {
                newUnsharedDecks.splice(index,1)
                let newSharedDeck = [
                    deck[0],
                    deck[1],
                    " "
                ]
                newSharedDecks.push(newSharedDeck)
                return true;
            }
        })
        this.setState({
            unsharedDecks:  newUnsharedDecks,
            sharedDecks:    newSharedDecks
        })
    }

    linkClassHandler = (classId, googleClassId) => {
        var newLinkedClasses = this.state.linkedGoogleClasses;
        newLinkedClasses.push(googleClassId);
        this.setState({linkedGoogleClasses:newLinkedClasses})
    }

    newStudentCallback = (newStudents) => {
        let newInvitedStudents = newStudents.filter(studentId=>{
            var isNewStudent = true;
            this.state.invitedStudents.forEach(invitedStudentId=>{
                if (invitedStudentId===studentId) { isNewStudent = false; }
            })
            this.state.enrolledStudents.forEach(enrolledStudentId=>{
                if (enrolledStudentId===studentId) { isNewStudent = false; }
            })
            return isNewStudent;
        })
        this.setState({invitedStudents:this.state.invitedStudents.concat(newInvitedStudents)})
    }

    // Non-State Property Management

    setSession = (newSession) => {
        this.session = newSession
    }

    // Server Interaction

    postGoogleGrades = () => {
        console.log("Posting grades to google")
        console.log(this.state.linkedGoogleClasses)
    }

    inviteToClassUsernames = (userNames) => {
        console.log("Invitation pressed")
        inviteToClassUsernames(this.state.classId,userNames).then(()=>{
            console.log("Successfully invited students");
        },(err)=>{
            console.error(err);
        })
    }

    addAssignmentToGoogleClassroom = async (deckId,classId,request) => {
        if (this.state.linkedGoogleClasses != null) {

            // Extracting the dueDate and dueTime
            let re = new RegExp('([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})');
            let match = request.dueDate.match(re);

            // Configure the assignment data structure
            const newCourseworkPayload = {
                title: request.title,
                description: request.instructions,
                dueDate: {
                    year: match[1],
                    month: match[2],
                    day: match[3],
                },
                dueTime: {
                    hours: match[4],
                    minutes: match[5],
                    seconds: match[6],
                    nanos: 0,
                },
                state: 'PUBLISHED',
                maxPoints: 100,
                workType: 'ASSIGNMENT',
            }

            // Add the assignment to each linked class
            this.state.linkedGoogleClasses.forEach(googleClassId => {
                // Add the assignment to each class
                addAssignmentToGoogleClassrooms(googleClassId,newCourseworkPayload).then((response)=>{
                    putGoogleAssignmentLinks(deckId,classId,parseInt(response.googleAssignmentId)).then(()=>{
                        // Assignment Added
                    },(err)=>{
                        console.error(err);
                    })
                },(err)=>{
                    console.error(err);
                })
            })

        }
    }

    addAssignmentToClass = async (request) => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "addAssignmentToClass",
                classId: request.classId,
                deckId: request.deckId,
                title: request.title,
                instructions: request.instructions,
                dueDate: request.dueDate,
                classesGoogle: []
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                console.error(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        // Add the assignment to Google Classroom
                        currentComponent.addAssignmentToGoogleClassroom(request.deckId,request.classId,request);
                    } else {
                        console.error(payload.body)
                    }
                } else {
                    console.error(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }

    getTeacherAssignments = async (classId) => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getTeacherAssignments",
                classId: classId,
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                console.error(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        // Record the currently assigned study sets
                        currentComponent.setState({"assignmentProgress":JSON.parse(payload.assignmentProgress)})
                    } else {
                        console.error(payload.body)
                    }
                } else {
                    console.error(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }
    
    getGoogleStudents = async () => {
        var currentComponent = this;
        // Get all Linked Google Classes
        getLinkedGoogleClasses(this.state.classId).then((response) => {

            this.setState({linkedGoogleClasses:response.linkedClasses});

            response.linkedClasses.forEach(googleClassId => {
                // Get all Student Ids for each class
                listStudentIds(googleClassId).then((response) => {
                    // Find all google classroom students not already in the thivos class
                    let currentStudents = currentComponent.state.enrolledStudents.concat(currentComponent.state.invitedStudents);
                    inviteToClassUsernames(response.userNames.filter(x => !currentStudents.includes(x))).then((response) => {
                        // TODO: Immediately add students to the state
                    },(err) => {
                        console.log(err);
                    })
                },(err) => {
                    console.log(err);
                })
            })
        },(err) => {
            console.log(err);
        })
    }

    linkToGoogleClassroom = async () => {
        // Get Potential Classrooms
        var currentComponent = this;
        window.gapi.client.classroom.courses.list().execute(function(response) {
            // Courses : { id, name }
            if (typeof response.courses !== 'undefined') {
                currentComponent.setState({'classesGoogle':response.courses})
            } else {
                currentComponent.setState({'classesGoogle':[]})
            }
        });

        // Show the modal
        this.setLinkToGoogleClassroomModal(true);
    }

    getClassHeader = async () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getClassHeader",
                classId: parseInt(this.values.id),
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                console.error(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        let parsedPayload = JSON.parse(payload.class)
                        currentComponent.setTitle(parsedPayload[1])
                        currentComponent.setDescription(parsedPayload[2])
                        currentComponent.setTeacherUsername(parsedPayload[3])
                        // Calling Dependent Interactions
                        currentComponent.getUnsharedDecks();
                    } else {
                        console.error(payload.body)
                    }
                } else {
                    console.error(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }

    getUnsharedDecks = async () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getUnsharedDecks",
                classId: parseInt(this.values.id),
                userName: this.state.teacherUsername
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                console.error(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        let parsedPayload = JSON.parse(payload.decks)
                        currentComponent.setUnsharedDecks(parsedPayload)
                    } else {
                        console.error(payload.body)
                    }
                } else {
                    console.error(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }

    getSharedDecks = async () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getSharedDecks",
                classId: parseInt(this.values.id)
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                console.error(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        let parsedPayload = JSON.parse(payload.decks)
                        currentComponent.setSharedDecks(parsedPayload)
                    } else {
                        console.error(payload.body)
                    }
                } else {
                    console.error(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }

    getStudentsInvited = async () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getStudentsInvited",
                classId: parseInt(this.values.id)
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                console.error(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        var parsedPayload = JSON.parse(payload.students).map(element=>element[0])
                        currentComponent.setInvitedStudents(parsedPayload)
                    } else {
                        console.error(payload.body)
                    }
                } else {
                    console.error(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }

    getStudentsInClass = async () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getStudentsInClass",
                classId: parseInt(this.values.id)
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                console.error(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        let parsedPayload = JSON.parse(payload.students).map(element=>element[0])
                        currentComponent.setEnrolledStudents(parsedPayload)
                    } else {
                        console.error(payload.body)
                    }
                } else {
                    console.error(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }

    unenrollFromClass = async(userName) => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "unenrollFromClass",
                classId: parseInt(this.values.id),
                userName: userName 
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                console.error(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        // Update to remove the element
                        var newEnrolledList = currentComponent.state.enrolledStudents;
                        let removeIndex = newEnrolledList.indexOf(userName);
                        newEnrolledList.splice(removeIndex,1);
                        currentComponent.setEnrolledStudents(newEnrolledList);
                    } else {
                        console.error(payload.body)
                    }
                } else {
                    console.error(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }

    uninviteFromClass = async(userName) => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "unenrollFromClass",
                classId: parseInt(this.values.id),
                userName: userName 
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                console.error(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        // Update to remove the element
                        var newInvitedList = currentComponent.state.invitedStudents;
                        let removeIndex = newInvitedList.indexOf(userName);
                        newInvitedList.splice(removeIndex,1);
                        currentComponent.setInvitedStudents(newInvitedList);
                    } else {
                        console.error(payload.body)
                    }
                } else {
                    console.error(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }

    // Graphics

    SharedDecks = (props) => {
        return (
            this.state.sharedDecks.map(deck =>
                <Button onClick={()=>this.props.history.push(`/set?id=${deck[0]}`)} className="SharedDeck">
                    <div>{deck[1]}</div>
                    <ColoredLine color="white"/>
                    <div>{deck[2]}</div>
                </Button>
            )
        )
    }

    AssignmentProgress = (props) => {
        return (
            this.state.assignmentProgress.map(assignment => 
                <Button className="SharedDeck" onClick={()=>this.props.history.push(`/set?id=${assignment[4]}`)}>
                    <div className="DisplayAssignmentTopRow">
                        <div className="DisplayAssignmentTitle">{assignment[0]}</div>
                        <div className="DisplayAssignmentNumComplete">{`${assignment[3]} Students Complete`}</div>
                    </div>
                    <ColoredLine color="white"/>
                    <div className="DisplayAssignmentBottomRow">
                        <div className="DisplayAssignmentInstructions">{assignment[1]}</div>
                        <div className="DisplayAssignmentDueDate">{`Due: ${assignment[2]}`}</div>
                    </div>
                </Button>
            )
        )
    }

    InvitedStudents = (props) => {
        return (
            <div className="RosterListSection">
                <div className="RosterListSectionHeader"> 
                    Invited Students
                </div>
                <this.InvitedStudentsList students={this.state.invitedStudents}/>
            </div>
        )
    }

    InvitedStudentsList = (props) => {
        if (this.state.invitedStudents.length === 0) {
            return (
                <div className="LastStudentRow">
                    No Invited Students
                </div>
            )
        } else {
            return (
                this.state.invitedStudents.map(student => 
                    <div className="StudentRow">
                        <div className="StudentRowName">
                            {student}
                        </div>
                        <Button className="StudentRowButton" onClick={()=>this.uninviteFromClass(student)}>
                            Delete
                        </Button>
                    </div>
                )
            )
        }
    }

    EnrolledStudents = (props) => {
        return (
            <div className="RosterListSection">
                <div className="RosterListSectionHeader">
                    Enrolled Students
                </div>
                <this.EnrolledStudentsList students={this.state.enrolledStudents}/>
            </div>
        )
    }

    EnrolledStudentsList = (props) => {

        let lastCell = this.state.enrolledStudents[this.state.enrolledStudents.length-1]

        if (this.state.enrolledStudents.length === 0) {
            return (
                <div className="LastStudentRow">
                    No Enrolled Students
                </div>
            )
        } else {
            return (
                this.state.enrolledStudents.map(student =>
                    (lastCell[0] === student) ?
                        // Return this if last row
                        <div className="LastStudentRow">
                            <div className="StudentRowName">
                                {student}
                            </div>
                            <Button className="StudentRowButton" onClick={()=>this.unenrollFromClass(student)}>
                                Delete
                            </Button>
                        </div>
                        :
                        // Else return this
                        <div className="StudentRow">
                            <div className="StudentRowName">
                                {student}
                            </div>
                            <Button className="StudentRowButton" onClick={()=>this.unenrollFromClass(student)}>
                                Delete
                            </Button>
                        </div>
                )
            )
        }
    }

    MenuSubContent = (props) => {
        if (this.state.mode === "studySet") {
            return (
                <div className="StudySets">
                    {   // Conditional Section ///////////////////////////
                        this.state.userName=== this.state.teacherUsername &&
                        //////////////////////////////////////////////////
                        <Button className="StudySetHeaderButton" onClick={()=>this.setShareStudySetModal(true)}>
                            + Share Study Set
                        </Button>
                    }
                    <this.SharedDecks/>
                </div>
            )
        }
        if (this.state.mode === "students") {
            return (
                <div className="StudySets">
                    {   // Conditional Section ///////////////////////////
                        this.state.userName=== this.state.teacherUsername &&
                        //////////////////////////////////////////////////
                        <Button className="StudySetHeaderButton" onClick={()=>this.setAddStudentsModal(true)}>
                            + Add Students
                        </Button>
                    }
                    <this.InvitedStudents/>
                    <this.EnrolledStudents/>
                </div>
            );
        }
        if (this.state.mode === "assignments") {
            return (
                <div className="StudySets">
                    <>
                        {   // Conditonal Section ////////////////////////////
                            this.state.userName === this.state.teacherUsername &&
                            //////////////////////////////////////////////////
                            <Button className="StudySetHeaderButton" onClick={()=>this.setAddAssignmentModal(true)}>
                                + Create Assignment
                            </Button>
                        }
                        {   /* // Conditonal Section ////////////////////////////
                            this.state.userName === this.state.teacherUsername &&
                            this.state.accountHasGoogleIdentity &&
                            //////////////////////////////////////////////////
                            <Button className="StudySetHeaderButton" onClick={()=>this.postGoogleGrades()}>
                                Post Grades to Google
                            </Button> */
                        }
                    </>
                    <this.AssignmentProgress/>
                </div>
            )
        }
    }

    RenderUnsharedDecks = (props) => {
        return (
            this.state.unsharedDecks.map(deck =>
                <div className="StudySetRow">
                    <div className="StudySetRowTitle">
                        {deck[1]}
                    </div>
                    <ShareButton deckShareHandler={this.deckShareHandler} deckId={deck[0]} classId={parseInt(this.values.id)}/>
                </div>
            )
        )
    }

    UnsharedGoogleClasses = (props) => {
        var isUnshared = (googleClass) => {
            var output = true;
            this.state.linkedGoogleClasses.forEach((googleClassId) => {
                if (googleClassId == parseInt(googleClass.id)) {
                    output = false;
                }
            })
            return output;
        }
        return (
            this.state.classesGoogle.filter(isUnshared).map(googleClass => 
                <div className="GoogleClassRow">
                    <div className="GoogleClassRowTitle">
                        {googleClass.name}
                    </div>
                    <ShareGoogleClassButton assignments={this.state.assignmentProgress} newStudentCallback={this.newStudentCallback} classId={this.state.classId} linkClassHander={this.linkClassHandler} googleClassId={googleClass.id}/>
                </div>
            )
        )
    }

    UnassignedStudySets = (props) => {

        // Get all study sets in the class that are not already assigned
        let unassignedAssignments = this.state.sharedDecks.filter((x) => {
            // Return true if the study set in question is not contained in assignmentProgress
            let deckId = x[0];
            var output = true;
            this.state.assignmentProgress.forEach((assignment) => {
                if (deckId === assignment[4]) {
                    output = false;
                }
            })
            return output;
        })


        return (
            // {Study Sets} - {Assignments}
            unassignedAssignments.map(deck => 
                <div className="AssignmentRow">
                    <div className="AssignmentRowTitle">
                        {deck[1] /* Deck Title */ }
                    </div>
                    <AssignStudySetButton deckId={deck[0]} classId={this.state.classId} configureAssignment={this.configureAssignment}/>
                </div>
            )
        )
    }

    render() {

        let title = signalMissingTitle(this.state.title)
        let description = signalMissingDescription(this.state.description)

        return (
            <>
                <Modal
                    show={this.state.shareStudySetModal}
                    centered="true"
                    scrollable="true"
                    onHide={()=>this.setShareStudySetModal(false)}>
                    
                    <Modal.Header closeButton>
                        <Modal.Title>Share Study Sets</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="StudySetRows">
                        <this.RenderUnsharedDecks/>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.addStudentsModal}
                    centered="true"
                    scrollable="true"
                    onHide={()=>this.setAddStudentsModal(false)}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            Invite Students
                        </Modal.Title>
                    </Modal.Header>

                    <ClassInvitationList parent={this}/>

                </Modal>

                <Modal
                    show={this.state.linkToGoogleClassroomModal}
                    centered="true"
                    scrollable="true"
                    onHide={()=>this.setLinkToGoogleClassroomModal(false)}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            Link To Google Classroom Class
                        </Modal.Title>
                    </Modal.Header>

                    <this.UnsharedGoogleClasses/>

                </Modal>

                <Modal
                    show={this.state.addAssignmentModal}
                    centered="true"
                    scrollable="true"
                    onHide={()=>this.setAddAssignmentModal(false)}>
                    
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Assign a Study Set
                        </Modal.Title>
                    </Modal.Header>

                    <this.UnassignedStudySets/>

                </Modal>

                <Modal
                    show={this.state.configureAssignmentModal}
                    centered="true"
                    scrollable="true"
                    onHide={()=>this.setState({configureAssignmentModal:false})}
                    >

                    <Modal.Header closeButton>
                        <Modal.Title>
                            Configure Your Assignment
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form onSubmit={ (e) => {
                            e.preventDefault();

                            let currentComponent = this;

                            let re = new RegExp('([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})');
                            let match = this.state.assignmentDueDate.match(re);
                            let specifiedDate = new Date(match[1],match[2],match[3]);
                            let currentDate = new Date();
                            if (currentDate.getDate() >= specifiedDate.getDate()) {
                                alert("Please enter a time in the future");
                                return;
                            }

                            let request = {
                                deckId: this.state.newAssignmentConfiguration.deckId,
                                classId: this.state.newAssignmentConfiguration.classId,
                                title: this.state.assignmentTitle,
                                instructions: this.state.assignmentInstructions,
                                dueDate: this.state.assignmentDueDate,
                            }
                            addAssignmentToClass(
                                this.state.newAssignmentConfiguration.deckId,
                                this.state.newAssignmentConfiguration.classId,
                                this.state.assignmentTitle,
                                this.state.assignmentInstructions,
                                this.state.assignmentDueDate
                            ).then(()=>{

                                // Add Assignment to the Current State
                                var currentAssignments = currentComponent.state.assignmentProgress;
                                currentAssignments.push([
                                    request.title,
                                    request.instructions,
                                    request.dueDate,
                                    0,
                                    request.deckId
                                ])
                                this.setState({assignmentProgress:currentAssignments})

                                this.addAssignmentToGoogleClassroom(request.deckId,
                                    request.classId,
                                    {
                                        title: request.title,
                                        instructions: request.instructions,
                                        dueDate: request.dueDate
                                    })
                            })
                            this.setState({configureAssignmentModal:false})

                        }}>
                            <Form.Group controlId="assignmentTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control 
                                    required
                                    default=""
                                    value={this.state.assignmentTitle}
                                    onChange={(e)=>this.setState({assignmentTitle:e.target.value})}
                                />
                            </Form.Group>
                            <Form.Group controlId="assignmentInstructions">
                                <Form.Label>Instructions</Form.Label>
                                <Form.Control 
                                    required
                                    default=""
                                    value={this.state.assignmentInstructions}
                                    onChange={(e)=>this.setState({assignmentInstructions:e.target.value})}
                                />
                            </Form.Group>
                            <Form.Group controlId="assignmentDueDate">
                                <Form.Label>Due Date (Ex: 2020-12-31 23:59:59)</Form.Label>
                                <Form.Control 
                                    required 
                                    default=""
                                    value={this.state.assignmentDueDate}
                                    placeholder="YYYY-MM-DD HH:MM:SS"
                                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}"
                                    onChange={(e)=>this.setState({assignmentDueDate:e.target.value})}
                                />
                            </Form.Group>
                            <Button type="submit" className="StudentRowButton">
                                Assign
                            </Button>
                        </Form>
                    </Modal.Body>

                </Modal>

                <div key="ClassHomeMenu">
                    <Navbar className="ClassMenuHeader">
                        <Navbar.Brand onClick={()=>this.props.history.push('/home')}>
                            <img alt="Thivos Logo" src={Logo} height='70'/>
                        </Navbar.Brand>
                        <div className="ClassMenuHeaderInfo">
                            {title}
                        </div>
                        <Nav activeKey={this.state.mode} className="ClassMenuSelector" variant="tabs" onSelect={(e)=>this.setMode(e)}>
                            <Nav.Item>
                                <Nav.Link eventKey="studySet">Study Sets</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="assignments">Assignments</Nav.Link>
                            </Nav.Item>
                            <> 
                                {   // Conditional Rendering Section /////////////////
                                    this.state.userName === this.state.teacherUsername &&
                                    //////////////////////////////////////////////////
                                    <Nav.Item>
                                        <Nav.Link eventKey="students">Students</Nav.Link>
                                    </Nav.Item>
                                }
                            </>
                        </Nav>
                        <>
                            {   // Conditional Rendering Section /////////////////
                                this.state.userName === this.state.teacherUsername &&
                                this.state.accountHasGoogleIdentity &&
                                //////////////////////////////////////////////////
                                <Button className="GoogleClassroomLinkButton" onClick={()=>this.linkToGoogleClassroom()}>
                                    Link to Google Classroom
                                </Button>
                            }
                        </>
                    </Navbar>
                    <this.MenuSubContent/>
                </div>
            </>
        )
    }
};

export default withRouter(ClassHome);