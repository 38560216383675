import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@material/react-text-field/dist/text-field.css';
import './App.css';
import EditHome from './containers/EditHome';
import SignUp from './containers/SignUp';
import ResetPassword from './containers/ResetPassword';
import ConfirmReset from './containers/ConfirmReset';
import MainMenu from './containers/MainMenu';
import ClassHome from './containers/ClassHome';
import StudyMenu from './containers/StudyMenu';
import queryString from 'query-string';

import { CognitoUserPool, CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import { config } from "./containers/Constants";

var accountLinkedToGoogle = (identities) => {
    var output = false;
    identities.forEach((identity) => {
        if (identity.providerName === "Google") {
            output = true;
        }
    })
    return output;
}

class Root extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loginByAccessCode();
  }

  // Google API Access


  setRedirectDestination = (newRedirectDestination) => {
    this.setState({
      redirectDestination : newRedirectDestination
    });
  }

  loginByAccessCode = () => {
    var parse = queryString.parse(this.props.location.search);
    var currentComponent = this;
    if (parse.code !== undefined) {
      fetch("https://thivos.auth.us-east-2.amazoncognito.com/oauth2/token", {
        body: 
      		`grant_type=authorization_code&client_id=40h7bi1nhm79mdh6r5na8igv84&redirect_uri=${config.uri.REDIRECT_URI_ENCODED}&code=${parse.code}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST"
      })
        .then(data => { return data.json(); })
        .then(json => {
          if (json.access_token !== undefined && json.refresh_token !== undefined && json.id_token !== undefined) {

            const AccessToken = new CognitoAccessToken({AccessToken: json.access_token});
            const IdToken = new CognitoIdToken({IdToken: json.id_token});
            const RefreshToken = new CognitoRefreshToken({RefreshToken: json.refresh_token});
            const sessionData = {
              IdToken: IdToken,
              AccessToken: AccessToken,
              RefreshToken: RefreshToken
            }

            const cachedSession = new CognitoUserSession(sessionData);
            if (cachedSession.isValid()) {

              currentComponent.props.setSession(sessionData)

              let destination = window.localStorage.getItem('lastLocation');
              if (destination === null || destination==null) {
                currentComponent.setRedirectDestination('home/');
              } else {
                console.log(typeof destination)
                console.log(destination)
                currentComponent.setRedirectDestination(destination);
              }

            } else {
              // Signal invalid tokens
              console.error("Tokens are invalid");
            }

          } else {
            // Signal missing tokens
            console.error("Missing tokens: ",json)
          }
        })
        .catch(error => {
          // Something else went wrong
          console.error("Error: ",JSON.stringify(error))
        })
    } else {
      // Missing code, no authentication
      // Re-authenticate
      window.location.replace(config.url.SIGNOUT_URL);
    }
  }

  // Graphics

  render() {
    if (this.state != null && this.state.redirectDestination !== undefined) {
      return(
        <Redirect to={this.state.redirectDestination}/>
      );
    } else {
      return(
        <></>
      );
    }
  }
}

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      session: null,
    }

    // Load gapi
    var tag = document.createElement('script');
    tag.src = 'https://apis.google.com/js/api.js';
    tag.addEventListener('load',()=>{
      window.gapi.load('client:auth2',this.initClient)
    });
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(tag);
  }

  initClient = () => {
    var currentComponent = this;
    console.log("REACT GAPI LOADED")
    console.log(window.gapi)
    window.gapi.client.init({
      'apiKey'          : 'AIzaSyB4cU4AOsjgyA1GDgbnqkFNEmnYw3WAELQ',
      'clientId'        : '204715155240-v3fem4e13rsrtvs7i4sn3it8qqpmut0r.apps.googleusercontent.com',
      'scope'           : 'https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/classroom.coursework.students',
      'discoveryDocs'   : ['https://classroom.googleapis.com/$discovery/rest?version=v1']
    }).then(function() {

      console.log("Voila")

      if (currentComponent.state.session.IdToken.payload.identities != undefined && accountLinkedToGoogle(currentComponent.state.session.IdToken.payload.identities)) {
        // Handle changes in sign-in status

        console.log("Voila")

        window.gapi.auth2.getAuthInstance().signIn();

        if (window.gapi.auth2.getAuthInstance().isSignedIn.get() !== true) {
          window.gapi.auth2.getAuthInstance().signIn();
        }
      }

    }).then(function() {
      // Successful authentication
    })
  }

  setSession = (newSession) => {
    this.setState({
      session: newSession,
    })
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route 
            exact path="/" 
            render={(props) => <Root {...props} setSession={this.setSession} session={this.state.session} />}
          />
          <Route 
            path="/set" 
            render={(props) => <EditHome {...props} session={this.state.session} />}
          />
          <Route 
            path="/signup" 
            render={(props) => <SignUp {...props} session={this.state.session} />}
          />
          <Route 
            path="/confirmreset" 
            render={(props) => <ConfirmReset {...props} session={this.state.session} />}
          />
          <Route 
            path="/reset" 
            render={(props) => <ResetPassword {...props} session={this.state.session} />}
          />
          <Route 
            path="/home" 
            render={(props) => <MainMenu {...props} session={this.state.session} />}
          />
          <Route 
            path="/class" 
            render={(props) => <ClassHome {...props} session={this.state.session} />}
          />
          <Route 
            path="/study" 
            render={(props) => <StudyMenu {...props} session={this.state.session} />}
          />
          <Route
            render={()=>{
              console.log("PASSED DEFAULT")
              window.localStorage.setItem('lastLocation','/home');
              return (
                <Redirect to={'/'}/>
              );
            }}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
