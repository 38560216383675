import React from 'react';
import { Button } from 'react-bootstrap';

import ColoredLine from './ColoredLine'

var isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
}

var signalMissingTitle = (rawText) => {
    if (isEmptyOrSpaces(rawText)) {
        return "No Title";
    } else {
        return rawText;
    }
}

var signalMissingDescription = (rawText) => {
    if (isEmptyOrSpaces(rawText)) {
        return "No Description";
    } else {
        return rawText;
    }
}

class EnrolledClassComponent extends React.Component {

    render() {
        var title = signalMissingTitle(this.props.class[1]);
        var description = signalMissingDescription(this.props.class[2]);
        return (
            <div className="EnrolledClass">
                <div>{title} | {this.props.class[3]}</div>
                <ColoredLine color="white" />
                <div>{description}</div>
                <ColoredLine color="white" />
                <Button>Open</Button>
            </div>
        )
    }
}

export default EnrolledClassComponent;