import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import { MDBInput } from 'mdbreact';

class ClassInvitationList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            inviteList: [""],
        }

        this.lastElement = React.createRef();

    }

    // State Management

    setInvitee = (index, newValue) => {
        var newInviteList = this.state.inviteList;
        newInviteList[index] = newValue;
        this.setState({
            inviteList: newInviteList,
        })
    }

    pushStudent = () => {
        this.setState({
            inviteList: this.state.inviteList.concat([""])
        })
    }

    // Backend Communication

    inviteToClassUsernames = async() => {
        this.props.parent.inviteToClassUsernames(this.state.inviteList);
    }

    // Graphical Components

    InviteListElements = (props) => {
        return (
            this.state.inviteList.map((invitee,index) =>
                <MDBInput 
                    value={invitee}
                    onChange={(e)=>this.setInvitee(index,e.currentTarget.value)}
                />
            )
        )
    }

    render() {
        return (
            <>
                <Modal.Body>
                    <div id="inviteList">
                        <this.InviteListElements/>
                    </div>
                    <Button onClick={()=>this.pushStudent()}>
                        Add Student
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.inviteToClassUsernames}>
                        Submit
                    </Button>
                </Modal.Footer>
            </>
        )
    }

}

export default ClassInvitationList;