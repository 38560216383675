import React from "react";
import { Button, Navbar, Modal, Form } from "react-bootstrap";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import '../design/Login.css';
import Lambda from 'aws-sdk/clients/lambda';
import AWS from 'aws-sdk';
import Logo from '../design/logo192.png';
import '../design/EditHome.css';
import ColoredLine from "./subComponents/ColoredLine";
import queryString from 'query-string';

import { withRouter } from 'react-router';
import { MDBInput } from 'mdbreact';
import { config } from './Constants';

import ShareButton from './subComponents/ShareButton';

import { addAssignmentToGoogleClassrooms } from '../serverCalls/googleClassroom';
import { addAssignmentToClass, putGoogleAssignmentLinks, getLinkedGoogleClasses } from '../serverCalls/awsLambda';

class EditHome extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cards: [],
            title: this.props.title,
            owner: "",
            edit: false,
            shareModal: false,
            teacher: 0,
            unsharedClasses: [],
        }

        this.values = queryString.parse(this.props.location.search);
        this.changed = false;
    }

    componentDidMount() {
        if (this.props.session == null) {
            let fullPath = this.props.history.location.pathname.concat(this.props.history.location.search)
            window.localStorage.setItem('lastLocation',fullPath)
            this.props.history.push("/");
        } else {
            window.localStorage.setItem('lastLocation','/home')
            this.userName = this.props.session.AccessToken.payload.username
            this.getCards();
            this.getDeckHeader();
            this.getSettings();
            this.getUnsharedClasses();
        }
    }

    setSession = (newSession) => {
        this.session = newSession
    }

    setCards = (newCards) => {
        this.setState({
            cards: newCards,
        })
    }

    deleteCard = (index) => {
        let newCards = this.state.cards;
        newCards.splice(index,1);
        this.setState({
            cards: newCards,
        })
    }

    createBlankCard = (index) => {
        let newCards = this.state.cards;
        let blankCard = {
            "answer"        :   " ",
            "wrongAnswer1"  :   " ",
            "wrongAnswer2"  :   " ",
            "wrongAnswer3"  :   " ",
            "rank"          :   " ",
            "deckId"        :   parseInt(this.values.id),
            "cardId"        :   index,
            "type"          :   "multipleChoice",
            "prompt"        :   " ",
        };
        newCards.splice(index,0,blankCard);
        this.setState({
            cards: newCards,
        })
    }

    setEdit = (edit) => {
        this.setState({
            edit: edit,
        })
    }

    setTitle = (newTitle) => {
        this.setState({
            title: newTitle,
        })
    }

    setOwner = (newOwner) => {
        this.setState({
            owner: newOwner,
        })
    }

    setShareModal = (isVisible) => {
        this.setState({
            shareModal: isVisible,
        })
    }

    setTeacher = (isTeacher) => {
        this.setState({
            teacher: isTeacher,
        })
    }

    setUnsharedClasses = (classes) => {
        this.setState({
            unsharedClasses: classes,
        })
    }

    componentWillUnmount = () => {
        if (this.changed) {
            if (window.confirm("You have unsaved changes. Press OK to save them.")) {
                this.saveChanges()
            } else {
                // Do nothing
            }
        }
    }

    addCard = () => {
        var newCards = this.state.cards;
        let newCardIndex = newCards.length
        newCards.push({
            deckId: this.props.location.state.deckId,
            cardId: newCardIndex,
            prompt: " ",
            rank: " ",
            type: "multipleChoice",
            answer: " ",
            wrongAnswer1: " ",
            wrongAnswer2: " ",
            wrongAnswer3: " "
        });
        this.setCards(newCards);
    }

    startStudying = () => {
        this.props.history.push(`/study?id=${this.values.id}`)
    }

    addAssignmentToGoogleClassroom = async (deckId,classId,request) => {
        if (this.state.linkedGoogleClasses != null) {

            // Extracting the dueDate and dueTime
            let re = new RegExp('([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})');
            let match = request.dueDate.match(re);

            // Configure the assignment data structure
            const newCourseworkPayload = {
                title: request.title,
                description: request.instructions,
                dueDate: {
                    year: match[1],
                    month: match[2],
                    day: match[3],
                },
                dueTime: {
                    hours: match[4],
                    minutes: match[5],
                    seconds: match[6],
                    nanos: 0,
                },
                state: 'PUBLISHED',
                maxPoints: 100,
                workType: 'ASSIGNMENT',
            }

            // Add the assignment to each linked class
            this.state.linkedGoogleClasses.forEach(googleClassId => {
                // Add the assignment to each class
                addAssignmentToGoogleClassrooms(googleClassId,newCourseworkPayload).then((response)=>{
                    putGoogleAssignmentLinks(deckId,classId,parseInt(response.googleAssignmentId)).then(()=>{
                        // Assignment Added
                    },(err)=>{
                        console.error(err);
                    })
                },(err)=>{
                    console.error(err);
                })
            })

        }
    }
    
    saveChanges = () => {
        let successMessage = "Save successful"
        if (this.changed === true) {

            AWS.config.update({region:"us-east-2"})
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
            })
            let currentComponent = this;
            const lambda = new Lambda({
                credentials: AWS.config.credentials
            });

            //////////////////////////////////////////////////
            // putCards //////////////////////////////////////
            //////////////////////////////////////////////////
            var params1 = {
                FunctionName: 'thivosCards',
                Payload: JSON.stringify({
                    requestName: "putCards",
                    userName: currentComponent.userName,
                    deckId: parseInt(this.values.id),
                    cards: this.state.cards,
                })
            };
            var success1 = false;
            lambda.invoke(params1, function(err,data) {
                if (err) {
                    alert(err.message)
                } else {
                    let response = data
                    if (response.StatusCode === 200) {
                        let payload = JSON.parse(response.Payload)
                        if (payload.statusCode === 200) {
                            success1 = true;
                        } else {
                            alert("ERROR: "+payload.statusCode)
                        }
                    } else {
                        alert(`ERROR: ${response.StatusCode}`)
                    }
                }
            });

            //////////////////////////////////////////////////
            // updateDeck ////////////////////////////////////
            //////////////////////////////////////////////////
            var params2 = {
                FunctionName: 'thivosLambda1_1',
                Payload: JSON.stringify({
                    requestName: "updateDeck",
                    deckId: parseInt(this.values.id),
                    newDeckTitle: this.state.title,
                    random: 0
                })
            };
            var success2 = false;
            lambda.invoke(params2, function(err,data) {
                if (err) {
                    alert(err.message)
                } else {
                    let response = data
                    if (response.StatusCode === 200) {
                        let payload = JSON.parse(response.Payload)
                        if (payload.statusCode === 200) {
                            success2 = true;
                            currentComponent.changed = false;
                        } else {
                            alert("ERROR: "+payload.statusCode)
                        }
                    } else {
                        alert(`ERROR: ${response.StatusCode}`)
                    }
                }
            });

            if (success1 && success2) {
                alert("Save successful")
            }

        } else {
            alert(successMessage)
        }
    }

    getDeckHeader = async () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getDeckHeader",
                deckId: parseInt(currentComponent.values.id),
            })
        };
        lambda.invoke(params, function(err,data) {
            if (err) {
                alert(err.message)
            } else {
                let response = data;
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload);
                    if (payload.statusCode === 200) {
                        currentComponent.setTitle(payload.title);
                        currentComponent.setOwner(payload.owner);
                    } else {
                        alert("ERROR: "+JSON.stringify(payload))
                    }
                } else {
                    alert(`AWS ERROR: ${response.StatusCode}`)
                }
            }
        });
    }

    getUnsharedClasses = async () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosLambda1_1',
            Payload: JSON.stringify({
                requestName: "getUnsharedClasses",
                userName: this.userName,
                deckId: parseInt(currentComponent.values.id),
            })
        };
        lambda.invoke(params, function(err,data) {
            if (err) {
                alert(err.message)
            } else {
                let response = data;
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload);
                    if (payload.statusCode === 200) {
                        currentComponent.setUnsharedClasses(JSON.parse(payload.classes))
                    } else {
                        alert("ERROR: "+JSON.stringify(payload))
                    }
                } else {
                    alert(`AWS ERROR: ${response.StatusCode}`)
                }
            }
        });
    }

    getCards = async () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosCards',
            Payload: JSON.stringify({
                requestName: "getCards",
                userName: currentComponent.userName,
                deckId: parseInt(this.values.id),
            })
        };
        lambda.invoke(params, function(err,data) {
            if (err) {
                alert(err.message)
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        currentComponent.setCards(payload.cards)
                    } else {
                        alert("ERROR: "+payload.statusCode)
                    }
                } else {
                    alert(`ERROR: ${response.StatusCode}`)
                }
            }
        });
    }

    deleteStudySet = () => {
        if (window.confirm("You are deleting this study set. This action cannot be undone.")) {
            AWS.config.update({region:"us-east-2"})
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
            })
            let currentComponent = this;
            const lambda = new Lambda({
                credentials: AWS.config.credentials
            });
            var params = {
                FunctionName: 'thivosLambda1_1',
                Payload: JSON.stringify({
                    requestName: "deleteDeck",
                    deckId: parseInt(currentComponent.values.id),
                })
            };
            lambda.invoke(params, function(err,data) {
                if (err) {
                    alert(err.message)
                } else {
                    let response = data;
                    if (response.StatusCode === 200) {
                        let payload = JSON.parse(response.Payload);
                        if (payload.statusCode === 200) {
                            currentComponent.props.history.push("/home")
                        } else {
                            alert("ERROR: "+JSON.stringify(payload))
                        }
                    } else {
                        alert(`AWS ERROR: ${response.StatusCode}`)
                    }
                }
            });
        }
    }

    handleDelete = (cardIndex) => {
        var newCards = this.state.cards;
        newCards.splice(cardIndex,1);
        this.setCards(newCards);
    }

    onCardChange = (card) => {
        this.changed = true;
        var newCards = []
        for (let i=0; i<this.state.cards.length; i+=1) {
            if (i === card.cardId) {
                newCards.push(card)
            } else {
                newCards.push(this.state.cards[i])
            }
        }
        this.setCards(newCards)
    }

    setSingleCard = (index, newCardData) => {
        var newCards = this.state.cards;
        newCards[index] = newCardData;
        this.setCards(newCards);
    }

    renderCards() {
        return (
            this.state.cards.map((thisCard,index) => 
                <this.Question data={thisCard} onChange={(e)=>this.setSingleCard(index,e)} edit={this.state.edit} index={index}/>
                // <div>{<CardComponent onCardChange={this.onCardChange.bind(this)} delete={this.handleDelete} card={thisCard}/>}</div>
            )
        )
    }

    UpdateQuestionData = (oldQuestionData,key,newValue) => {
        this.changed = true;
        var newQuestionData = oldQuestionData;
        newQuestionData[key] = newValue;
        return newQuestionData;
    }

    deckShareHandler = (deckId, classId) => {
        let newUnsharedClasses = this.state.unsharedClasses.filter((thisClass)=>{
            return classId != thisClass[0];
        });
        this.setState({
            unsharedClasses:newUnsharedClasses,
            shareModal:false
        })
    }

    deckAssignHandler = (deckId, classId) => {
        let newUnsharedClasses = this.state.unsharedClasses.filter((thisClass)=>{
            return classId != thisClass[0];
        });
        this.setState({
            unsharedClasses:newUnsharedClasses,
            configureAssignmentModal:true,
            shareModal:false,
            newAssignmentConfiguration:{
                deckId:deckId,
                classId:classId
            }
        });
    }

    AssignButton = (props) => {
        return (
            <></>
        )
    }

    UnsharedSets = (props) => {
        return (
            props.unsharedClasses.map(myClass => 
                <>
                    <div className="ShareClassWithRow">
                        <div style={{"flex":1}}>{myClass[1]}</div>
                        <ShareButton text={"Share"} classId={myClass[0]} deckId={props.deckId} deckShareHandler={this.deckShareHandler} />
                        <ShareButton text={"Assign"} classId={myClass[0]} deckId={props.deckId} deckShareHandler={this.deckAssignHandler} />
                    </div>
                    <ColoredLine color="grey"/>
                </>
            )
        )
    }

    Question = (props) => {
        if (props.edit) {
            return (
                <div className="Question">
                    <div className="QuestionHeader">
                        <div className="QuestionHeaderRow1">
                            <div className="QuestionHeaderRow1Label">
                                Question
                            </div>
                            <Button onClick={()=>this.deleteCard(props.index)} className="DeleteButton">
                                Delete
                            </Button>
                        </div>
                        <MDBInput
                            className="BlackTextInput"
                            value={props.data.prompt}
                            onChange={(e)=>props.onChange(this.UpdateQuestionData(props.data,"prompt",e.currentTarget.value))}
                        />
                    </div>
                    <div className="AnswersTable">
                        <div className="AnswersRow">
                            <div className="CorrectAnswerCell">
                                <div>
                                    Correct Answer
                                </div>
                                <MDBInput
                                    className="BlackTextInput"
                                    value={props.data.answer}
                                    onChange={(e)=>props.onChange(this.UpdateQuestionData(props.data,"answer",e.currentTarget.value))}
                                />
                            </div>
                            <div className="AnswersCell">
                                <div>Wrong Answer 1</div>
                                <MDBInput
                                    className="BlackTextInput"
                                    value={props.data.wrongAnswer1}
                                    onChange={(e)=>props.onChange(this.UpdateQuestionData(props.data,"wrongAnswer1",e.currentTarget.value))}
                                />
                            </div>
                        </div>
                        <div className="AnswersRow">
                            <div className="AnswersCell">
                                <div>
                                    Wrong Answer 2
                                </div>
                                <MDBInput
                                    className="BlackTextInput"
                                    value={props.data.wrongAnswer2}
                                    onChange={(e)=>props.onChange(this.UpdateQuestionData(props.data,"wrongAnswer2",e.currentTarget.value))}
                                />
                            </div>
                            <div className="AnswersCell">
                                <div>Wrong Answer 3</div>
                                <MDBInput
                                    className="BlackTextInput"
                                    value={props.data.wrongAnswer3}
                                    onChange={(e)=>props.onChange(this.UpdateQuestionData(props.data,"wrongAnswer3",e.currentTarget.value))}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="Question">
                    <div className="QuestionHeader">
                        {props.data.prompt}
                    </div>
                    <div className="AnswersTable">
                        <div className="AnswersRow">
                            <div className="CorrectAnswerCell">
                                {props.data.answer}
                            </div>
                            <div className="AnswersCell">
                                {props.data.wrongAnswer1}
                            </div>
                        </div>
                        <div className="AnswersRow">
                            <div className="AnswersCell">
                                {props.data.wrongAnswer2}
                            </div>
                            <div className="AnswersCell">
                                {props.data.wrongAnswer3}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    TitleBar = (props) => {

        if (this.state.edit) {
            return (
                <div>
                    <MDBInput
                        className="InputBar"
                        value={this.state.title}
                        onChange={(e)=>this.setTitle(e.currentTarget.value)}
                    />
                    {this.state.owner}
                </div>
            )
        } else {
            return (
                <div>
                    {this.state.title}
                    <ColoredLine color="white"/>
                    {this.state.owner}
                </div>
            )
        }
    }

    getSettings = async () => {
        AWS.config.update({region:"us-east-2"})
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId : 'us-east-2:70fd82c4-8f67-4516-9bb0-341901737908'
        })
        let currentComponent = this;
        const lambda = new Lambda({
            credentials: AWS.config.credentials
        });
        var params = {
            FunctionName: 'thivosCards',
            Payload: JSON.stringify({
                requestName: "getSettings",
                userName: currentComponent.userName
            })
        }
        lambda.invoke(params, function(err,data) {
            if (err) {
                alert(err.message);
            } else {
                let response = data
                if (response.StatusCode === 200) {
                    let payload = JSON.parse(response.Payload)
                    if (payload.statusCode === 200) {
                        currentComponent.setTeacher(payload.isTeacher)
                    } else {
                        alert(payload.body)
                    }
                } else {
                    alert(`ERROR: ${response.StatusCode}`)
                }
            }
        })
    }

    updateStudySet = () => {
        // Update the deck header and the study set material
        this.saveChanges();
        this.setEdit(false);
        this.changed = false;
    }

    Actions = (props) => {
        if (this.state.edit === true) {
            return (
                <div className="Actions">
                    <Button onClick={this.deleteStudySet} className="DeleteButton">Delete</Button>
                    <Button onClick={this.updateStudySet} className="ActionsElement">Save</Button>
                </div>
            )
        } else if (this.state.owner != null && this.state.owner === this.userName) {
            return (
                <div className="Actions">
                    <Button className="ActionsElement" onClick={()=>this.setEdit(true)}>Edit</Button>
                    <Button className="ActionsElement" onClick={()=>this.setShareModal(true)}>Share</Button>
                    <Button className="ActionsElement" onClick={()=>this.startStudying()}>Study</Button>
                </div>
            )
        } else {
            return (
                <div className="Actions">
                    <Button className="ActionsElement" onClick={()=>this.setShareModal(true)}>Share</Button>
                    <Button className="ActionsElement" onClick={()=>this.startStudying()}>Study</Button>
                </div>
            )
        }
    }

    copyTextToClipoard = (text) => {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position="fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log(`Copying text was ${msg}`);
        } catch (err) {
            console.error(`Copying failed: ${err}`)
        }
    }

    TeacherShare = () => {
        if (this.state.teacher === 1) {
            return (
                <>
                    <div className="TeacherShareHeader">
                        <Modal.Title>
                            Share with class
                        </Modal.Title>
                    </div>
                    <Modal.Body>
                        <this.UnsharedSets unsharedClasses={this.state.unsharedClasses} deckId={parseInt(this.values.id)}/>
                    </Modal.Body>
                </>
            )
        } else {
            return <></>
        }
    }

    ActionBar = (props) => {
        if (props.edit) {
            return (
                <div className="SetMenuActionBar">
                    <Button onClick={()=>this.createBlankCard(0)} className="SetMenuActionBarButton">
                        + Add Card
                    </Button>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    render() {
        return (
            <div className="HomeBackground">

                <Modal
                    show={this.state.shareModal}
                    centered="true"
                    scrollable="true"
                    onHide={()=>this.setShareModal(false)}>

                    <Modal.Header closeButton>
                        <Modal.Title>Share</Modal.Title>
                    </Modal.Header>
                    <div className="ShareableLinkBlock">
                        <Modal.Title>Shareable Link</Modal.Title>
                        <div className="ShareableLinkBar">
                            <div>{window.location.href}</div>
                            <div className="ShareSpace"></div>
                            <Button className="ActionsElement" onClick={()=>this.copyTextToClipoard(window.location.href)}>Copy</Button>
                        </div>
                    </div>
                    <this.TeacherShare/>
                </Modal>

                <Modal
                    show={this.state.configureAssignmentModal}
                    centered="true"
                    scrollable="true"
                    onHide={()=>this.setState({configureAssignmentModal:false})}
                    >

                    <Modal.Header closeButton>
                        <Modal.Title>
                            Configure Your Assignment
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form onSubmit={ (e) => {
                            e.preventDefault();

                            let currentComponent = this;

                            let re = new RegExp('([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})');
                            let match = this.state.assignmentDueDate.match(re);
                            let specifiedDate = new Date(match[1],match[2],match[3]);
                            let currentDate = new Date();
                            if (currentDate.getDate() >= specifiedDate.getDate()) {
                                alert("Please enter a time in the future");
                                return;
                            }

                            let request = {
                                deckId: this.state.newAssignmentConfiguration.deckId,
                                classId: this.state.newAssignmentConfiguration.classId,
                                title: this.state.assignmentTitle,
                                instructions: this.state.assignmentInstructions,
                                dueDate: this.state.assignmentDueDate,
                            }
                            addAssignmentToClass(
                                this.state.newAssignmentConfiguration.deckId,
                                this.state.newAssignmentConfiguration.classId,
                                this.state.assignmentTitle,
                                this.state.assignmentInstructions,
                                this.state.assignmentDueDate
                            ).then(()=>{

                                getLinkedGoogleClasses(request.classId).then((response)=>{
                                    
                                    this.setState({linkedGoogleClasses:response.linkedClasses})
                                    this.addAssignmentToGoogleClassroom(
                                        request.deckId,
                                        request.classId,
                                        {
                                            title: request.title,
                                            instructions: request.instructions,
                                            dueDate: request.dueDate
                                    })
                                },(err)=>{
                                    console.error(err);
                                })
                            })
                            this.setState({configureAssignmentModal:false})

                        }}>
                            <Form.Group controlId="assignmentTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control 
                                    required
                                    default=""
                                    value={this.state.assignmentTitle}
                                    onChange={(e)=>this.setState({assignmentTitle:e.target.value})}
                                />
                            </Form.Group>
                            <Form.Group controlId="assignmentInstructions">
                                <Form.Label>Instructions</Form.Label>
                                <Form.Control 
                                    required
                                    default=""
                                    value={this.state.assignmentInstructions}
                                    onChange={(e)=>this.setState({assignmentInstructions:e.target.value})}
                                />
                            </Form.Group>
                            <Form.Group controlId="assignmentDueDate">
                                <Form.Label>Due Date (Ex: 2020-12-31 23:59:59)</Form.Label>
                                <Form.Control 
                                    required 
                                    default=""
                                    value={this.state.assignmentDueDate}
                                    placeholder="YYYY-MM-DD HH:MM:SS"
                                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}"
                                    onChange={(e)=>this.setState({assignmentDueDate:e.target.value})}
                                />
                            </Form.Group>
                            <Button type="submit" className="StudentRowButton">
                                Assign
                            </Button>
                        </Form>
                    </Modal.Body>

                </Modal>

                <Navbar className="Header justify-content-between">
                    <Navbar.Brand onClick={()=>this.props.history.push('/home')}>
                        <img alt="Thivos Logo" src={Logo} height='70'/>
                    </Navbar.Brand>
                    <this.TitleBar title={this.state.title} owner={this.state.owner} />

                    {/* I kind of like what this does, so I'm leaving it */}
                    <ColoredLine color="white"/>

                    <this.Actions/>

                </Navbar>
                <this.ActionBar edit={this.state.edit}/>
                <div>{this.renderCards()}</div>
            </div>
        )
    }
}

export default withRouter(EditHome);