import React from 'react';

const ColoredLine = ({color}) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            borderColor: color,
            border: 0,
            height: 1
        }}
    />
)

export default ColoredLine;