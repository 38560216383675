
import React from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import '../design/Login.css';

import { CognitoUserPool, CognitoUserAttribute } from "amazon-cognito-identity-js";

class SignUp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            email: "",
            cognitoUser: "",
            confirm: false,
            confirmationCode: "",
        }
    }

    setConfirmationCode = (newConfirmationCode) => {
        this.setState({
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
            cognitoUser: this.state.cognitoUser,
            confirm: this.state.confirm,
            confirmationCode: newConfirmationCode,
        })
    }

    setConfirm = (confirm) => {
        this.setState({
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
            cognitoUser: this.state.cognitoUser,
            confirm: confirm,
            confirmationCode: this.state.newConfirmationCode,
        })
    }

    setUsername = (newUsername) => {
        this.setState({
            username: newUsername,
            password: this.state.password,
            email: this.state.email,
            cognitoUser: this.state.cognitoUser,
            confirm: this.state.confirm,
            confirmationCode: this.state.newConfirmationCode,
        })
    }

    setPassword = (newPassword) => {
        this.setState({
            username: this.state.username,
            password: newPassword,
            email: this.state.email,
            cognitoUser: this.state.cognitoUser,
            confirm: this.state.confirm,
            confirmationCode: this.state.newConfirmationCode,
        })
    }

    setEmail = (newEmail) => {
        this.setState({
            username: this.state.username,
            password: this.state.password,
            email: newEmail,
            cognitoUser: this.state.cognitoUser,
            confirm: this.state.confirm,
            confirmationCode: this.state.newConfirmationCode,
        })
    }

    setCognitoUser = (newCognitoUser) => {
        this.setState({
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
            cognitoUser: newCognitoUser,
            confirm: this.state.confirm,
            confirmationCode: this.state.newConfirmationCode,
        })
    }

    confirmAccount = () => {
        var cognitoUser = this.state.cognitoUser;
        var signupComp = this;
        cognitoUser.confirmRegistration(this.state.confirmationCode, true, function(err, result) {
            if (err) {
                alert(err.message);
                return;
            }
            signupComp.props.history.push("/");
        });
    }

    createAccount = () => {

        // Basic error cases
        if (this.state.username.length === 0) {
            alert("Please enter a username.")
            return
        }
        if (this.state.password.length === 0) {
            alert("Please enter a password")
            return
        }
        if (!this.validEmail(this.state.email)) {
            alert("Please enter a valid email address")
            return
        }

        // AWS Authentication : Pool Information
        var poolData = {
            UserPoolId : 'us-east-2_6ae0DTaEB', // your user pool id here
            ClientId : '40h7bi1nhm79mdh6r5na8igv84' // your app client id here
        };
        var userPool = new CognitoUserPool(poolData);

        // AWS Authentication : Sign Up Information
        var attributeList = [];
        var dataEmail = {
            Name : 'email',
            Value : this.state.email // your email here
        };
        var attributeEmail = new CognitoUserAttribute(dataEmail);
        attributeList.push(attributeEmail);

        var signupComp = this;

        var cognitoUser;
        userPool.signUp(this.state.username, this.state.password, attributeList, null, function(err, result){
            if (err) {
                alert(err.message);
                return;
            }
            cognitoUser = result.user;
            console.log('user name is ' + cognitoUser.getUsername());
            signupComp.setCognitoUser(cognitoUser);
            signupComp.getConfirmation();
        });
    }

    validEmail = (theEmail) => {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(theEmail)) {
            return (true)
        }
        return (false)
    }

    getConfirmation = () => {
        this.setUsername("");
        this.setConfirm(true);
    }

    render() {
        if (this.state.confirm) {
            return (
                <div>
                    <form className="Login">
                        <FormGroup controlId="confirmationCode" bsSize="large">
                            <FormLabel>Confirmation Code</FormLabel>
                            <FormControl
                                autoFocus
                                type="confirmationCode"
                                value={this.state.confirmationCode}
                                onChange={e => this.setConfirmationCode(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button block bsSize="large" onClick={this.confirmAccount}>Confirm</Button>
                        </FormGroup>
                    </form>
                </div>
            )

        } else {
            return (
              <div>
                <form className="Login">
                  <FormGroup controlId="username" bsSize="large">
                    <FormLabel>Username</FormLabel>
                    <FormControl
                      autoFocus
                      type="username"
                      value={this.state.username}
                      onChange={e => this.setUsername(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup controlId="password" bsSize="large">
                    <FormLabel>Password</FormLabel>
                    <FormControl
                      value={this.password}
                      onChange={e => this.setPassword(e.target.value)}
                      type="password"
                    />
                  </FormGroup>
                  <FormGroup controlId="email" bsSize="large">
                      <FormLabel>Email</FormLabel>
                      <FormControl
                          value={this.email}
                          onChange={e => this.setEmail(e.target.value)}
                          type="email"
                          >
                      </FormControl>
                  </FormGroup>
                  <FormGroup>
                      <Button block bsSize="large" onClick={this.createAccount}>Create Account</Button>
                  </FormGroup>
                </form>
              </div>
            );
        }
    }
}

export default SignUp;